import React from 'react'
import Navbar from '../common/Navbar'
import Adventure from '../adventure/Adventure'
import Recomandation from '../common/Recomandation'
import { useLocation} from "react-router-dom";
import Footer from '../../footer/footer'
import Discover from '../common/Discover'
import { Col, Row } from 'react-bootstrap'
import transformationHome from '../../../assets/image/transformationHome.png';
import Transformation from "../transformation/Transformation";


const TransformHome = (pageName) => {
    const location = useLocation();
    // const { pageName } = location.state || {};

    // console.log("location.state ==>", location.state);
    console.log("pageName==>", pageName);
  return (
   <>
      <Navbar />
                <div >
                <Row  >
                <Col xs={12} className="position-relative text-white w-100 my-4">
                    {/* <div>
                    <img
                        src={transformationHome}
                        alt=""
                        className="experience-theme-image w-100 mt-5 "
                        style={{ objectFit: "cover" }}
                    />
                    </div> */}
                    
                </Col>
            </Row>
            </div>
            <Transformation />

                <Discover />
                <Recomandation />
                <Footer />
   </>
  )
}

export default TransformHome
