import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import discoverImg from "../../../assets/image/Discover.png";
import "../../../assets/css/discover.css";

const Discover = () => {
  const navigate = useNavigate();

  return (
   <div>
 <Container fluid className="discover-main-container">
      <Row className="justify-content-center">
        {/* <Col > */}
          {/* <div className="discover-container "> */}
            <Row className="align-items-center ">
              <Col xs={12} md={6} className="text-center text-md-start">
                <img
                  src={discoverImg}
                  alt="Discover Yourself"
                  className=" discover-image "
                />
              </Col>
              <Col xs={12} md={6} className="d-flex flex-column justify-content-center text-center text-md-start">
                <div className="mt-4 mt-md-0 discoverd-ai">
                  <h5 className="discover-title text-center ">Discover yourself though AI</h5>
                  <p className="discover-description">
                    Create a personalized life transformational tour<br></br> for yourself who deserves to be happy.
                  </p>
                  <Button
                    className="text-light discover-button mt-2 w-60 align-content-center  w-md-auto"
                    onClick={() => navigate("/personalizedTrip")}
                  >
                    <span className="build-btn">Build your AI recommendation Trip</span>
                  </Button>
                </div>
              </Col>
            </Row>
          {/* </div> */}
        {/* </Col> */}
      </Row>
    </Container>

   </div>
  );
};

export default Discover;
