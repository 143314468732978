// import React, { useState } from 'react';
// import { GoogleMap, useLoadScript, Marker, Circle, InfoWindow } from '@react-google-maps/api';

// const customIcon = {
//   url: "../../assets/image/Star 13.png",
//   scaledSize: new window.google.maps.Size(38, 38), // Adjust the size as needed
//   anchor: new window.google.maps.Point(22, 94) // Adjust the anchor as needed
// };

// const MapView = ({ locationData }) => {
//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: "AIzaSyAF8Wpa6HaYM0Sp3B-RnnO8wtpYoTIV-2Q",
//   });
//   console.log("locationdata==>",locationData)
//   console.log("locationdata==>",locationData.placeId)
//   const [mapCenter, setMapCenter] = useState({ lat: locationData?.latitude, lng: locationData?.longitude });
//   const [selectedLocation, setSelectedLocation] = useState(null);

//   if (loadError) return <div>Error loading maps</div>;
//   if (!isLoaded) return <div>Loading Maps</div>;

//   if (!locationData) return null;

//   const position = { lat: locationData.latitude, lng: locationData.longitude };

//   const mapContainerStyle = {
//     height: '400px',
//     width: '100%'
//   };

//   const options = {
//     center: position,
//     zoom: 15, // Adjust the zoom level to a lower value to give a wider view
//   };

//   const handleMarkerClick = () => {
//     setSelectedLocation(position);
//   };

//   const handleCircleClick = () => {
//     setSelectedLocation(position);
//   };

//   const handleCloseClick = () => {
//     setSelectedLocation(null);
//   };

//   return (
//     <GoogleMap mapContainerStyle={mapContainerStyle} 
//     defaultCenter={ position}

//     center={position} zoom={options.zoom}>
//       <Marker position={position} icon={customIcon} onClick={handleMarkerClick} />
//       <Circle center={position} radius={200} options={{ fillColor: 'red', strokeColor: 'red' }} onClick={handleCircleClick} />
      
//       {selectedLocation && (
//         <InfoWindow position={selectedLocation} onCloseClick={handleCloseClick}>
//           <div>
//             Latitude: {selectedLocation.lat.toFixed(4)}, Longitude: {selectedLocation.lng.toFixed(4)}
//           </div>
//         </InfoWindow>
//       )}
//     </GoogleMap>
//   );
// };

// export default MapView;

import React, { useState, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker, Circle, InfoWindow } from '@react-google-maps/api';

const customIcon = {
  url: "../../assets/image/Star 13.png",
  scaledSize: new window.google.maps.Size(38, 38), 
  anchor: new window.google.maps.Point(19, 37)
};

const MapView = ({ locationData }) => {
  console.log("locationdata=================>",locationData)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey:"AIzaSyAF8Wpa6HaYM0Sp3B-RnnO8wtpYoTIV-2Q", // Make sure to use your actual API key
    libraries: ["places"]
  });

  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 }); // Default center, you may adjust
  const [selectedLocation, setSelectedLocation] = useState(null);
  useEffect(() => {
    if (locationData && isLoaded) {
      if (locationData.placeId) {
        const service = new window.google.maps.places.PlacesService(document.createElement('div'));
        service.getDetails({
          placeId: locationData.placeId
        }, (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setMapCenter(place.geometry.location.toJSON());
            setSelectedLocation(place.geometry.location.toJSON());
          }
        });
      } else if (locationData.latitude && locationData.longitude) {
        // Directly use latitude and longitude if placeId is not available
        const latLng = { lat: locationData.latitude, lng: locationData.longitude };
        setMapCenter(latLng);
        setSelectedLocation(latLng);
      }
    }
  }, [locationData, isLoaded]);
  

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  const mapContainerStyle = {
    height: '400px',
    width: '100%'
  };

  const options = {
    zoom: 13, // Adjust the zoom level to a lower value to give a wider view
  };

  const handleMarkerClick = () => {
    setSelectedLocation(mapCenter);
  };

  const handleCloseClick = () => {
    setSelectedLocation(null);
  };

  return (
    <GoogleMap mapContainerStyle={mapContainerStyle} center={mapCenter} zoom={options.zoom}>
      <Marker position={mapCenter} icon={customIcon} onClick={handleMarkerClick} />
      {/* <Circle center={mapCenter} radius={200} options={{ fillColor: 'red', strokeColor: 'red' }} onClick={handleMarkerClick} /> */}
      <Circle 
  center={mapCenter} 
  radius={1000} // Radius in meters
  options={{
    fillColor: 'red',
    fillOpacity: 0.6,  // Set opacity to ensure visibility against various backgrounds
    strokeColor: 'red',
    strokeOpacity: 0.8,  // Slightly less opacity for the stroke for visual depth
    strokeWeight: 2      // Adjust the weight of the stroke
  }} 
  onClick={handleMarkerClick} // Handles click on the circle
/>
      {/* {selectedLocation && (
        <InfoWindow position={selectedLocation} onCloseClick={handleCloseClick}>
          <div>
            Latitude: {selectedLocation.lat.toFixed(4)}, Longitude: {selectedLocation.lng.toFixed(4)}
          </div>
        </InfoWindow>
      )} */}
    </GoogleMap>
  );
};

export default MapView;
