import React, { useEffect, useState } from "react";
import { Card, Button, Container, Row, Col, Carousel } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import "../../../assets/css/transformation.css";
import {
  ArrowLeft,
  ArrowRight,
  Bookmark,
  BookmarkFill,
  GeoAlt,
} from "react-bootstrap-icons";
import starImg from "../../../assets/image/Star 13.png";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const Transformation = ({ pageName }) => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const navigate = useNavigate();

  const location = useLocation();
  const [tourData, setTourData] = useState([]);
  const [displayedToursCount, setDisplayedToursCount] = useState(8);
  const [wishlist, setWishlist] = useState(new Set());
  const [displayedTours, setDisplayedTours] = useState([]);
  const [showAllTours, setShowAllTours] = useState(false);

  const [themeInfo, setThemeInfo] = useState({
    themeName: "",
    themeDesc: "",
    themeId: "",
  });

  const userData = JSON.parse(localStorage.getItem("user"));

  const handleTourCardClick = (tour) => {
    const tourId = tour.tourId;
    navigate(`/tour/${tourId}`, {
      state: {
        tourId,
        selectedThemeName: themeInfo.themeName,
        selectedThemeId: themeInfo.themeId,
        pageName: "/transformation",
      },
    });
  };

  const handleViewMore = () => {
    const nextTours = tourData?.slice(
      displayedTours?.length,
      displayedTours?.length + 4
    );
    setDisplayedTours([...displayedTours, ...nextTours]);
    setShowAllTours(
      displayedTours?.length + nextTours?.length >= tourData?.length
    );
  };

  useEffect(() => {
    fetchLocationDetails();
    if (tourData?.length > 0) {
      setDisplayedTours(tourData?.slice(0, Math.min(4, tourData?.length)));
      setShowAllTours(tourData?.length <= 4);
    }
  }, [tourData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const response = await fetch(`${BaseAPI}/tour/all/themes`);
        const data = await response.json();
        if (data.statusCode === 200) {
          const defaultTheme = data.data.find(
            (theme) => theme.themeName === "Transformation"
          );
          if (defaultTheme) {
            setThemeInfo({
              themeId: defaultTheme.themeId,
              themeName: defaultTheme.themeName,
              themeDesc: defaultTheme.themeDesc,
            });
            getTourByThemeId(defaultTheme.themeId);
          }
        } else {
          console.error("Failed to fetch themes. Status:", data.statusMessage);
        }
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };

    fetchData();
  }, []);

  const getTourByThemeId = async (themeId) => {
    try {
      const response = await fetch(`${BaseAPI}/tour/theme/${themeId}`);
      const data = await response.json();
      if (data.data) {
        const toursWithLocation = await Promise.all(
          data.data.map(async (tour) => {
            const { placeId } = tour.location || {};
            if (tour.location) {
              const locationDetails = await fetchLocationDetails(
                tour.location.placeId,
                tour.location.latitude,
                tour.location.longitude
              );
              return {
                ...tour,
                formattedAddress: locationDetails.formattedAddress,
              };
            } else {
              return { ...tour, formattedAddress: "Location not available" };
            }
          })
        );

        setTourData(toursWithLocation);
      }
      // setTourData(data.data);
    } catch (error) {
      console.error("Error fetching tours:", error);
    }
  };

  const addToWishlist = async (tourId) => {
    if (!userData?.userId) {
      alert("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/save/favTour`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Failed to add to wishlist: ${response.statusText}`);
      }

      const responseData = await response.json();

      if (responseData) {
        setWishlist((prevWishlist) => new Set(prevWishlist).add(tourId));
        toast.success("Added to your Wishlist");
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  const removeFromWishlist = async (tourId) => {
    if (!userData?.userId) {
      alert("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/delete/fav-tour`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to remove from wishlist: ${response.statusText}`
        );
      }

      const responseData = await response.json();

      if (responseData) {
        setWishlist((prevWishlist) => {
          const newWishlist = new Set(prevWishlist);
          newWishlist.delete(tourId);
          return new Set(newWishlist);
        });
        toast.success("Successfully removed from wishlist!");
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  const fetchLocationDetails = async (placeId, latitude, longitude) => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    if (!placeId && (latitude == null || longitude == null)) {
      console.error("No location link provided");
      return { formattedAddress: "Location not linked" };
    }
    let url;
    if (placeId) {
      url = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${apiKey}`;
    } else {
      url = ` https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    }
    try {
      const response = await axios.get(url);
      const data = response.data;

      if (data.status === "OK" && data.results.length > 0) {
        const formattedAddress = data.results[0].formatted_address;

        console.log("Formatted Address: ", formattedAddress);

        return { formattedAddress };
      } else {
        console.error("No results found");
        return { formattedAddress: "Unknown" };
      }
    } catch (error) {
      console.error("Failed to fetch location details:", error);
      return { formattedAddress: "Error" };
    }
  };

  const directionButtons = (direction) => {
    return (
      <span>
        {direction === "Next" ? (
          <ArrowRight className="rightArrow" />
        ) : (
          <ArrowLeft className="rightArrow" />
        )}
      </span>
    );
  };


   // Helper function to truncate address
   const truncateAddress = (address) => {
    const words = address.split(" ");
    if (words.length > 4) {
      return words.slice(0, 4).join(" ") + "...";
    }
    return address;
  };

  return (
    <Container fluid className="px-5 py-4 custom-container">
      <Row>
        <Col className="mb-4">
          <h2 className="display-4 font-schedule-title">
            {themeInfo.themeName}
          </h2>
          <p className="text-gray-700 dark:text-gray-400 font-feature-tour">
            {/* {themeInfo.themeDesc} */}
            Grow Through Nature Reawaken your best self with experiences in
            serene forests and wildlife, designed to ignite personal growth and
            renewal.
          </p>
        </Col>
      </Row>

      <Row>
        {displayedTours.length > 0 ? (
          displayedTours.map((tour) => (
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={tour.tourId}
              className="mb-4"
            >
              <Card className="h-100">
                {/* <div className="card-img-container">
                    <Card.Img
                      onClick={() => handleTourCardClick(tour)}
                      variant="top"
                      src={
                        tour.tourAssets.find((asset) => asset.assetType === "IMAGE" && asset.assetCategory=== "FEATURED" || asset.assetCategory=== "SNAPSHOTS")
                          ?.assetUrl
                      }
                      className="img-fluid rounded"
                    />
                  </div> */}

                <div className="carousel-hover">
                  <Carousel
                    className="carousel-overlay"
                    interval={null}
                    nextIcon={directionButtons("Next")}
                    prevIcon={directionButtons("Previous")}
                  >
                    {tour.tourAssets.map((asset, assetIdx) => (
                      <Carousel.Item
                        key={assetIdx}
                        className="card-img-container"
                      >
                        <img
                          onClick={() => handleTourCardClick(tour)}
                          className=" "
                          src={asset?.assetUrl}
                          alt={`Slide ${assetIdx}`}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <img
                    onClick={() => handleTourCardClick(tour)}
                    variant="top"
                    src={tour.tourAssets[0]?.assetUrl}
                    className=" card-img-container"
                  />
                  {/* <div className="overlay-text">
                    {tour.tourTheme[0].theme.themeName}
                  </div> */}
                  <div className="bookmark-icon">
                    {wishlist.has(tour.tourId) ? (
                      <BookmarkFill
                        onClick={() => removeFromWishlist(tour.tourId)}
                        style={{ color: "white" }}
                      />
                    ) : (
                      <Bookmark onClick={() => addToWishlist(tour.tourId)} />
                    )}
                  </div>
                </div>

                {/* <Card.Body className="d-flex flex-column">
                    <Card.Title className="font-medium-title">
                      {tour.title}
                    </Card.Title>
                    <Card.Text className="font-feature-tour ">
                      {tour.shortDescription}
                    </Card.Text>
                    <Card.Text className="mt-auto font-feature-tour ">
                      From {tour.currency} {Number(tour.price).toLocaleString()}
                    </Card.Text>
                  </Card.Body> */}

                <div className="d-flex flex-column mt-2">
                  <span className="font-medium-title feature-title">
                    {tour.title}
                  </span>
                  <Row>
                    <div>
                      <div className="d-inline z-30 font-feature-tour">
                        From <span className="me-2">{tour.currency}&nbsp;</span>{" "}
                        {tour.price}
                      </div>
                      <div className="float-end text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
                        Duration {tour.duration} {tour.durationTime}
                      </div>
                    </div>
                  </Row>
                  <Row className="mb-5">
                    <div className="flex justify-between gap-3 mt-auto col-12 ">
                      <div className="d-inline font-feature-tour transformation-tour-description col-lg-3 col-4">
                        <span className="black-star">
                          <img className="starImg" src={starImg} alt="star" />
                        </span>
                        <span className="ms-2">5.0</span>
                        <span className="dot"></span>
                        <span className="text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
                          3 Ratings
                        </span>
                      </div>

                      {/* <div
                        role="button"
                        className="float-end col-lg-5 col-6  font-medium location-text text-wrap"
                        onClick={() => handleTourCardClick(tour)}
                      >
                        <span className="font-medium ">
                          <GeoAlt className="text-muted" />
                          {tour.formattedAddress}
                        </span>
                      </div> */}

<div
  role="button"
  className="float-end col-lg-5 col-6 font-medium location-text text-wrap"
  onClick={() => handleTourCardClick(tour)}
  style={{
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  }}
>
  <span className="font-medium text-nowrap">
    <GeoAlt className="text-muted me-1" />
    {truncateAddress(tour.formattedAddress)}
  </span>
</div>

                    </div>
                  </Row>
                </div>
              </Card>
            </Col>
          ))
        ) : (
          <p className="text-center">Tours will be coming soon...</p>
        )}
      </Row>

      <Row className="text-center mt-4">
        {tourData?.length > 4 && !showAllTours && (
          <div className="d-flex justify-content-end me-5 mb-2">
            <Button className="view-more-btn" onClick={handleViewMore}>
              Load More Activities
            </Button>
          </div>
        )}
      </Row>
    </Container>
  );
};

export default Transformation;
