import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

const UpdateProfile = ({ showUpdatePopup, handleClose, userData }) => {
  console.log("userdata==>",userData)
  const BaseAPI = process.env.REACT_APP_PEEK_SERVER_ENDPOINT;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
  });

  useEffect(() => {
    if (userData) {
      setFormData({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        email: userData.email || "",
        mobileNumber: userData.mobileNumber || "",
      });
    }
  }, [userData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if(!formData.firstName){
      toast.warning("Please enter name");
      return;
    }
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(formData.mobileNumber)) {
      toast.warning("Please enter a valid 10-digit mobile number.");
      return;
    }
    console.log("formData==>", formData);
    const updatedUser = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      mobileNumber: formData.mobileNumber,
    };

    try {
      const response = await fetch(
        `${BaseAPI}/rest/auth/update/user/${userData.userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedUser),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update user");
      }
    const responseData = await response.json();
      toast.success("User updated successfully");
      handleClose()
      console.log("Response data:", responseData);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <>
      <Modal show={showUpdatePopup} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>My Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="p-3" onSubmit={handleFormSubmit}>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    className="w-100"
                    type="text"
                    value={formData.firstName}
                    onChange={handleChange}
                    name="firstName"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    className="w-100"
                    type="text"
                    value={formData.lastName}
                    onChange={handleChange}
                    name="lastName"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    className="w-100"
                    type="email"
                    value={formData.email}
                   readOnly
                    name="email"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    className="w-100"
                    type="tel"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    name="mobileNumber"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Col sm={12} className="mt-2" style={{ textAlign: "center" }}>
              <Button variant="primary mt-2" type="submit">
                Update
              </Button>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default UpdateProfile;
