import React from "react";
import Navbar from '../pages/common/Navbar';
import Footer from "./footer";

const Terms = () => {
    return (
        <>
        <Navbar/>
        <div className="container mt-5 pt-5">
            <h1>Terms & Conditions for Customers and Merchants</h1>
            <br/>
            <h3>For Customers</h3>
            <br/>
            <h4>TERMS AND CONDITIONS</h4>
            <p>(By participating in 360 Explorer's (360 Explorer Inc./ 360 Explorer LLP / EUrekaTrip) Trek/Expeditions, you agree to the following Terms and Conditions)</p>
            <p>You must accept these terms and conditions to join a Trekking/Tours/Expedition with 360 Explorers LLP.</p>

            <h4>ELIGIBILITY:</h4>
            <p>Participants must be in suitable physical and mental condition at the time of their Trekking departure date. Participants must fully understand the potential risks involved in the activity undertaken. Participants under the age of 18 must have absolute consent from a legal guardian witnessed by at least two independent parties.</p>
            
            <h5>1) Modes of Payment:</h5>
            <p>Online | Cash (With Taxes)</p>
            <p>Any cancellation request made should be in writing to the Email address. Participants accept that all cancellation settlements will be managed by 360 Explorers LLP.</p>
            
            <h5>2) TRANSFER AND CHANGES</h5>
            <p>If you wish to change the date of a Trekking or get transferred into another Trek/Expedition/Tour you have booked with us, please inform us and make changes no later than 10 days before the commencement of the Trekking in Maharashtra, 30 Days before the International Expedition. We are not responsible for any Airline Booking.</p>
            
            <h5>3) RISKS AND SAFETY</h5>
            <p>All participants taking part in any 360 Explorer LLP Trekking Events acknowledge and fully understand that these Trekking activities involve elements of risk depending on the nature of the challenge. While 360 Explorers aim to safeguard participants' safety and health, we cannot be held responsible for damage, illness, or injury sustained during or as a result of your participation. You thus will take part entirely at your own risk and agree to indemnify us and our partners, the charity, our employees, agents, sub-contractors, and suppliers against claims for any loss or damage to personal property or for loss or consequential losses or claims through your participation in this challenge arising from your actions. You are responsible for bringing your equipment clearly mentioned in the kit list of the particular challenge's itinerary. We are not responsible in any way if you are not able to arrange adequate clothing and equipment, otherwise, you will not be allowed to continue on a Trek. All participants must wear safety clothing or equipment as mentioned by guides or may be required in the country concerned or under the rules and regulations of any local service provider for any activity undertaken by you. Trek guides and leaders at any stage have the right to stop the participant to continue on their respective Trek if they find that the participant is not adequately fit, healthy, adequately equipped, and is affecting the Trek safety and progress.</p>

            <h5>4) CHANGES OF ITINERARY</h5>
            <p>There is nothing certain in an adventure-based activity. Therefore participants must understand and acknowledge that the itinerary given for each Trek is an indication or a tentative schedule to achieve our aims and objectives and not a binding contract with us. Changes in the itinerary may be made with changes in the climatic conditions, mode of transportation, equipment use, and any other factors that are beyond our control. You agree to accept these changes at any time during the course of a Trek.</p>

            <h5>5) ACCOMMODATIONS</h5>
            <p>Accommodation used totally depends on the nature of the Trek and the area in which it is conducted. We can either use hostels, lodges, hotels, camping/tents, or a mix of any or all of these as our accommodation during our Trek. We reserve the right to change the accommodation due to unforeseen circumstances.</p>

            <h5>6) Special TRAVEL INSURANCE WITH ADVENTURE COVER</h5>
            <p>Adventure travel insurance is one of the essential requirements to book a Trek. All participants must have travel insurance with adventure cover, covering activities like (mountaineering, cycling, trekking, etc.). It should also cover medical (emergency, evacuation, and repatriation) arrangements, cancellation of the Trek, etc. We have a special policy customized for these Treks, but in case you wish to take another policy then you must purchase it. Please Read Terms of condition and cover of policy given by us and you have to accept it.</p>

            <h5>7) Limitation of our Liability</h5>
            <p>Participants acknowledge that 360 Explorers LLP Trekking involve elements of risk depending on the nature of the activity.</p>

            <h5>8) 360 Explorers LLP is not responsible for the following:</h5>
            <ul>
                <li>If anything happens before meeting us or boarding our transport at the meeting point or after you finish your Trek/Tour/Expedition and leave our services and transport.</li>
                <li>If for any reason you fail to reach the meeting point on time. Though we will try to help you in any way we reasonably could.</li>
                <li>Clarity of views and weather, Change of plan or loss due to International regulations, Pandemic situation, national regulations, etc.</li>
                <li>Numbers or quality of animals or any other wildlife you might hope to see on the tour.</li>
                <li>Any sort of medical problems or physical complications, including the ones, which you have told us about earlier.</li>
                <li>Anything with respect to goods and services you buy or accept during the Trek other than those arranged by us that is mentioned in the itinerary provided to you.</li>
                <li>Medical Emergencies or Injury or Death.</li>
                <li>Your overall behavior and conduct during the Trek and any sort of negligence, carelessness, or Recklessness.</li>
                <li>Local Customs, behavior, laws, and rules and regulations in a country/ State/ Village you may visit.</li>
                <li>You are responsible for your actions during the challenge/Trek and if you take part in an activity that is dangerous or places you in harm’s way, you do so of your own accord.</li>
                <li>You agree to indemnify and hold us harmless for all liabilities, loss, claims, criminal acts, and expenses that may arise from any breach of these terms and conditions by You, including any third-party liabilities incurred by us as a result of your actions.</li>
            </ul>

            <h4>9) MEDIA</h4>
            <p>You agree that any kind of photography, videography, or any other kind of media associated with the Trek, taken or made during, post, or pre-challenge, which may have you in it might be used for our future publicity material for our various archives and we will have full rights in all these media made by us or by you and no royalties or benefits of any kind will be extended for the same.</p>

            <h4>10) Behavior:</h4>
            <p>As part of your Trek, you will be visiting new places, countries, or states or villages having different rules and regulations. It’s your duty and responsibility to act appropriately in accordance with the laws at all times. Please always comply with instructions of guides and the Trek leaders. Consumption of alcohol is strictly banned on all programs organized by 360 Explorers LLP. Anybody found under the influence of alcohol at any point will be asked to disassociate from the group with immediate effect. No refund can be claimed against any such terminations.</p>

            <h4>11) If in 360 Explorers LLP opinion at any time your actions may cause accidents, injury, discomfort or damage or loss of property to local to or any tour members, we might terminate your Trek arrangements and logistics in consideration with our supplier. In such case 360 Explorers LLP or any third party vendors have no liability to you for any kind of refunds or compensation. Also, you are liable for any cost associated with your behavior, which we have incurred.</h4>

            <h4>12) We hereby do indemnify 360 Explorer LLP, its employer, its agent, its franchises, affiliates, and its representatives against any claims and or damages that we may suffer or our children may suffer during and post-adventure activity.</h4>
            <br/>
            <h4>CONFIDENTIAL INFORMATION AGREEMENT</h4>
            <p>While taking part in 360 Explorer’s adventure, climbers/trekkers agree to follow the below terms and conditions and maintain secrecy about the following Information.</p>

            <h4>1. Confidential Information:</h4>
            <p>1.1 You understand that during the course of your tour/adventure, you will have access to Confidential Information of the 360 Explorer company. You shall be obligated to use the Confidential Information only for the purpose of performing your duties. You shall, at all times during the term of your expedition/trek and at all times thereafter, keep confidential and secure the Confidential Information. The term “Confidential Information” as used in this agreement means any data, information, or knowledge about the Company or its customers, prospective customers, and/or suppliers that you have access to and is not generally known or available to the public and which is or should reasonably be understood to be confidential or proprietary information, including but not limited to:</p>
            <ul>
                <li>The terms of this Agreement;</li>
                <li>The Company’s business or operational plans or activities, existing or contemplated markets, advertising initiatives, methods of operation, products, or services;</li>
                <li>The Company’s financial projections, including but not limited to, annual sales forecasts and targets and any computation(s) of the market share of customers and/or customer prospects;</li>
                <li>The Company’s dealers, channel partners, suppliers, sellers, customers, prospective customers, or logistics data or their mobile numbers;</li>
                <li>The Company’s customer, dealer, channel partner, or supplier lists, cost of goods or services, profits and losses, budgeting, past or future sales, or financial information;</li>
                <li>The account terms and pricing upon which the Company obtains products and services from its suppliers;</li>
                <li>The terms and pricing of services rendered by the Company to its customers;</li>
                <li>The Company’s existing or contemplated designs, technical systems, models, or platforms, formulas, research, notes, or analytical data;</li>
                <li>The Company’s employees, salaries, job-related functions, duties, or responsibilities; and</li>
                <li>The Company’s Intellectual Property.</li>
            </ul>
            <p>1.2 You accept and acknowledge that all application data, documents, written presentations, brochures, drawings, memoranda, notes, records, files, correspondence, Mobile numbers of our partners, manuals, models, specifications, computer programs, e-mail, electronic databases, maps, drawings, architectural renditions, models, and all other writings or materials of any type including or embodying any Confidential Information shall be deemed Confidential Information and be subject to the same restrictions on disclosure applicable to Confidential Information pursuant to this agreement.</p>
            <p>1.3 You accept that this obligation to not disclose Confidential Information. Any act going against this agreement which causes loss of business will result in legal action against you.</p>

            <h4>READ, ACKNOWLEDGED, AND ACCEPTED</h4>

            <h2>MERCHANT AGREEMENT</h2>
            <p>This Merchant Agreement ("Merchant Agreement") contains the terms under which 360 Explorer Inc. (360 Explorer LLP) ("360 Explorer") agrees to grant the merchant identified on the applicable Service Order ("Merchant") access to and use of 360 Explorer’s online platform and services. By executing a Service Order that references this Merchant Agreement, the parties agree as follows:</p>

            <h4>1. DEFINITIONS</h4>
            <p>1.1. Affiliate: Any entity that, directly or indirectly, controls, is controlled by, or is under common control with such entity (but only for so long as such control exists), where “control” means owning more than 50% of the outstanding shares or securities representing the right to vote in the election of directors or other managing authority of such entity.</p>
            <p>1.2. Agreement: This Merchant Agreement together with each applicable Platform Addendum and the Service Order.</p>
            <p>1.3. Chargeback: A request to invalidate a payment that a Customer files directly with (a) his or her debit or credit card company; (b) his or her debit or credit card-issuing bank; or (c) any other entity that provides payment services.</p>
            <p>1.4. Confidential Information: Information in any form, whether oral or written, of a business, financial, or technical nature which is disclosed by a party (“Disclosing Party”) during the Term and which the recipient (“Receiving Party”) reasonably should know is confidential, but excluding the information listed in Section 12.2. The terms of this Agreement (including pricing) are each party’s Confidential Information.</p>
            <p>1.5. Customer(s): Any person or entity that reserves or purchases a Merchant Service from Merchant’s business, where the purchase is made through or entered into the 360 Explorer Platform. “Customer(s)” may be defined differently in the applicable Platform Addendum for that specific addendum.</p>
            <p>1.6. Effective Date: The date outlined in the Service Order.</p>
            <p>1.7. Fraudulent Charge: A credit or debit card charge by a Customer that is determined by the card company or payment processor to be fraudulent.</p>
            <p>1.8. Merchant: The entity identified as such in the Service Order.</p>
            <p>1.9. Merchant Content: (a) All text, graphics, logos, video, audio, information, data, software, and other content and materials relating to the Merchant Services which are produced or provided by or for Merchant, and (b) any and all copyrights, trademarks, and other intellectual property and proprietary rights related thereto, as now or hereafter constituted and in any form or media.</p>

            <p>1.10. Merchant Service(s): Any of Merchant’s activities, rentals, or other goods or services that the Merchant offers to Customers or otherwise manages using the 360 Explorer Platform. “Merchant Services” may be defined differently in the applicable Platform Addendum for that specific addendum.</p>
            <p>1.11. 360 Explorer Content: All information, data, products, materials, and all other content, software, and services made available on or through the 360 Explorer Platform or otherwise generally provided to Merchant by 360 Explorer. “360 Explorer Content” does not include Merchant Content.</p>
            <p>1.12. 360 Explorer Fees: The Commissions, Flat Fees, and other amounts payable to 360 Explorer, as set forth in the Service Order. “360 Explorer Fees” specifically exclude any credit card processing fees or charges.</p>
            <p>1.13. 360 Explorer Payments: The payment processing solutions operated by 360 Explorer.</p>
            <p>1.14. 360 Explorer Platform: The 360 Explorer services purchased by Merchant as set forth in the Service Order.</p>
            <p>1.15. Platform Addendum: An addendum attached to this Agreement, which contains terms specific to a purchased 360 Explorer Platform offering.</p>
            <p>1.16. Service Order: The 360 Explorer Service Order executed by 360 Explorer and Merchant referencing this Merchant Agreement.</p>
            <p>1.17. Voucher: A confirmation of purchase provided by 360 Explorer to a Customer after processing the Customer’s purchase.</p>

            <h4>2. THE SERVICES</h4>
            <p>2.1 Service Order, Product Schedules, Precedence: By executing a Service Order, Merchant orders the subscriptions to use one or more 360 Explorer Platform offerings pursuant to the Service Order. Each 360 Explorer Platform offering is also subject to the applicable Platform Addendum. If there is any conflict among any provisions of this Agreement, the descending order of precedence will be (unless expressly stated otherwise for any particular terms): Service Order, Platform Addendum, Merchant Agreement.</p>

            <p>2.2 Merchant Account: Merchant must set up an account with 360 Explorer in connection with Merchant’s use of the 360 Explorer Platform ("Merchant Account"). Merchant will designate one or more of its employees to be the point of contact with 360 Explorer for the management and support of the 360 Explorer Platform, and who will be responsible for establishing and managing Merchant’s use of the 360 Explorer Platform, including the creation of authentication credentials to access the Merchant Account. Merchant will safeguard all Merchant Account user authentication credentials in its possession or under its control and is responsible for acts and omissions of its Merchant Account users relating to this Agreement as though they were Merchant’s own. Merchant is responsible for all activities that occur under the Merchant Account and for the accuracy, quality, and integrity of the Merchant Content that Merchant or its users input into the 360 Explorer Platform. Merchant shall keep all Merchant Account information accurate, up-to-date, and current.</p>

            <p>2.3 License; Use of the 360 Explorer Platform: Subject to Merchant’s full compliance with this Agreement (including the applicable Platform Addendum) at all times, 360 Explorer grants to Merchant a limited non-exclusive, non-sublicensable, non-transferable, non-assignable, and revocable right and license during the Term to access and use the applicable 360 Explorer Platform offering solely for its internal business purposes. The use of the 360 Explorer Platform for any purpose not expressly permitted by this Agreement (including in an applicable Platform Addendum) is prohibited and will be deemed to be a material breach of this Agreement. The rights granted to Merchant to use each 360 Explorer Platform offering are set forth in the applicable Platform Addendum and are subject to any use limitations that may be set forth in the applicable Platform Addendum and the Service Order.</p>

            <p>2.3.1. EurekaTrip for Business: If Merchant has purchased 360 Explorer’s for business offering, the 360 Explorer Professional Software Addendum attached hereto will apply. Merchant acknowledges and consents that by purchasing the 360 Explorer business offering, 360 Explorer may, in its sole discretion, enroll Merchant and any Merchant Service in 360 Explorer’s EurekaTrip.com marketplace which includes 360 Explorer Pals (as defined in the 360 Explorer Activity Marketplace Addendum). Merchant further acknowledges that enrollment in the 360Explorer.com marketplace may result in increased Commission rates to 360 Explorer solely related to the 360 Explorer Activity Marketplace Addendum and Merchant shall pay any such increased Commission rates. Merchant may at any time opt-out of enrollment in the 360Explorer.com marketplace by giving 360 Explorer written notice of such decision to opt-out.</p>

            <p>2.3.2. EurekaTrip.com: If Merchant has enrolled in 360 Explorer’s EurekaTrip.com marketplace offering, the 360 Explorer Activity Marketplace Addendum attached hereto will apply.</p>
            <p>2.3.3 Reseller Hub: If Merchant has enrolled in 360 Explorer’s Reseller Hub offering, the 360 Explorer Reseller Hub Addendum attached hereto will apply.</p>

            <p>2.4 Changes to Agreement: From time to time 360 Explorer may change the terms of this Merchant Agreement or a Platform Addendum. 360 Explorer reserves the right to make any and all such changes. 360 Explorer will strive to provide advance notice of any change that materially alters the terms of this Merchant Agreement or a Platform Addendum.</p>

            <p>2.5 Electronic Messaging: 360 Explorer may include the ability within the 360 Explorer Platform to communicate with Customers using SMS, other text message mechanisms, email, or other electronic messaging platforms (each an “Electronic Message”). To the extent Customers opt-in to Electronic Messages, the following terms apply:</p>

            <p>2.5.1 Merchant expressly authorizes 360 Explorer, through its vendors or agents, to communicate with Customers through Electronic Messages.</p>
            <p>2.5.2 Merchant shall ensure that all Electronic Messages comply with applicable law.</p>
            <p>2.5.3 Merchant shall include the following or substantially similar language prominently in Merchant’s applicable terms and conditions or similar agreement with Customers:
            “By opting into text or other electronic messages, you: (i) expressly consent to be contacted by [MERCHANT NAME] or our agents for all purposes arising out of or relating to your relationship with us at the telephone number(s) you provide, including for marketing or promotional messages; (ii) agree we may contact you in any way, including SMS messages, and calls, if applicable, and messages delivered using auto telephone dialing system or an automatic texting system; and (iii) represent and warrant that you have read, or had the opportunity to read, and agree to our terms and conditions or similar agreement and privacy policy, located at: [insert hyperlinks]. While variable based on your purchase behavior, marketing-based text messages will not exceed 5 per month. You understand that consent is not a condition of purchase. You can unsubscribe from further text messages by replying STOP. Message and data rates may apply.”</p>
            <p>2.5.4 Merchant shall abide by all restrictions imposed by 360 Explorer regarding the use of Electronic Messages.</p>
            <p>2.5.5 Merchant hereby grants to 360 Explorer a limited, non-exclusive license to use Merchant’s name, marks, logos, and other identifiers as necessary to carry out the foregoing and as otherwise needed to send Electronic Messages.</p>
            <p>2.5.6 Merchant acknowledges that 360 Explorer is not responsible for any Electronic Messages or other communications that occur between Merchant and
Excessive Chargebacks</p>
            <p>4.4.2. If 360 Explorer Inc. (360 Explorer LLP) determines, at its sole discretion, that the Merchant is incurring or may incur excessive Chargebacks, it may establish controls or conditions governing the Merchant’s account, such as:</p>
            <ul>
                <li>(a) Establishing new processing fees and charging the Merchant for excessive dispute charges or chargeback fees imposed by a payment processor or credit card company.</li>
                <li>(b) Delaying Merchant Payments, including imposing an indefinite delay or modifying the Merchant’s payout plan.</li>
                <li>(c) Refusing the award of Chargebacks.</li>
                <li>(d) Terminating or suspending the Merchant’s access to the 360 Explorer Platform.</li>
                <li>(e) Creating a reserve fund of a reasonable amount, determined solely by 360 Explorer Inc., from Customer Payments to use for Chargebacks. After the maximum time period for a dispute window has closed following termination of a Service Order or the Agreement, 360 Explorer Inc. will remit to the Merchant any remaining balance in the reserve fund as part of the final Merchant Payment to the Merchant.</li>
            </ul>

            <h4>Fraudulent Charges</h4>
            <p>4.4.3. In the event of a Fraudulent Charge, the Merchant acknowledges and consents for 360 Explorer Inc. to deduct the full amount of the Fraudulent Charge, as well as any associated fees, fines, or penalties, received from Customer Payments from the next Merchant Payment due to the Merchant. If the Merchant’s next Merchant Payment is insufficient, the Merchant shall pay 360 Explorer Inc. the full balance upon invoicing as set forth in Section 4.1.</p>

            <h4>Refunds</h4>
            <p>4.5. In the event of a Fraudulent Charge or a payment of any refund to a Customer:</p>
            <ul>
                <li>(a) 360 Explorer Inc. will not charge or retain any applicable Commission on the applicable transaction.</li>
                <li>(b) 360 Explorer Inc. will not be responsible for paying any amount to the Merchant for such transaction, except to the extent otherwise expressly provided in a Platform Addendum.</li>
            </ul>

            <h4>Proprietary Rights</h4>
            <p>5.1. 360 Explorer Inc. Property and Rights. The Merchant acknowledges that 360 Explorer Inc. and its successors and assignees are and will remain the exclusive owners in all jurisdictions of the 360 Explorer Platform and 360 Explorer Content, including all patents, copyrights, trademarks, trade secrets, moral rights, and other intellectual property rights and proprietary rights related thereto, excluding only the Merchant Content (together, “360 Explorer Inc. Property and Rights”). All rights not expressly granted to the Merchant under this Agreement are hereby reserved by 360 Explorer Inc., and no license or other rights are being granted by 360 Explorer Inc. by implication, estoppel, or otherwise.</p>
            <p>5.2. Merchant Content. 360 Explorer Inc. acknowledges that, as between 360 Explorer Inc. and the Merchant, the Merchant is and will remain the exclusive owner in all jurisdictions of the Merchant Content, including all patents, copyrights, trademarks, trade secrets, moral rights, and other intellectual property rights and proprietary rights related thereto. The Merchant hereby grants to 360 Explorer Inc. a worldwide, nonexclusive, fully-paid up, royalty-free, transferable right and license (with the right to sublicense through multiple tiers) during the Term to copy, reproduce, distribute, disclose, publish, broadcast, communicate, display, perform (whether publicly or otherwise), host, store, prepare derivative works from, translate, modify, or combine with other data or information or content, or otherwise use any and all of the Merchant Content and to have others exercise such rights and license on behalf of 360 Explorer Inc. in connection with the Merchant Services.</p>
            <p>5.3. Merchant Input. 360 Explorer Inc. welcomes any feedback or other input that the Merchant may provide concerning improvements to the 360 Explorer Platform (“Input”). Input excludes the Merchant’s Confidential Information. By providing Input to 360 Explorer Inc., the Merchant acknowledges that all Input becomes 360 Explorer Inc.’s exclusive property, and the Merchant hereby assigns to 360 Explorer Inc. all its rights to Input, including all intellectual property rights in Input. At 360 Explorer Inc.’s request and expense, the Merchant shall execute documents and take such further action as 360 Explorer Inc. may reasonably request to assist it in acquiring, perfecting, or maintaining intellectual property rights in Input.</p>

            <h4>EurekaTrip Professional Software Addendum:</h4>
            <p>This document outlines the terms and conditions regarding the use of the EurekaTrip Professional Software by Merchants. Here's a summary of the key points:</p>
            <p>1. Definitions: Provides specific definitions for terms used throughout the document, such as "Customer(s)," "Merchant Online Channel(s)," "Merchant Service(s)," and "EurekaTrip Professional Software."</p>
            <p>2. EurekaTrip Professional Software License: Grants the Merchant a limited license to access and use the EurekaTrip Professional Software for internal business operations, subject to compliance with the agreement.</p>
            <p>3. Setup and Support: Specifies that EurekaTrip may provide setup assistance and support for the EurekaTrip Professional Software as per its standard policies or Service Order.</p>
            <p>4. Modifications: States that EurekaTrip may modify or update the EurekaTrip Professional Software without prior notice.</p>
            <p>5. Restrictions: Outlines various restrictions on the Merchant's use of the EurekaTrip Professional Software, including exclusivity, prohibition on third-party access, requirements regarding widgets and notices, and limitations on storing certain information.</p>
            <p>6. Fees: Specifies that EurekaTrip is entitled to receive compensation for Merchant Services purchased through the EurekaTrip Professional Software, including provisions for refunds.</p>
            <p>7. Data: Clarifies ownership and permissible use of individual Customer and sales information collected in connection with Merchant Services.</p>

            <h4>EurekaTrip Activity Marketplace Addendum:</h4>
            <p>This document provides specific terms and conditions related to the EurekaTrip Activity Marketplace. It defines relevant terms, outlines permitted actions and restrictions, addresses fee structures, and delineates data ownership and usage rights.</p>

            <h4>EurekaTrip Reseller Hub Addendum:</h4>
            <p>This document outlines terms and conditions related to the EurekaTrip Reseller Hub. It defines relevant terms, outlines permitted actions and restrictions, addresses fee structures, and delineates data ownership and usage rights.</p>
            <p>Overall, these addenda aim to establish clear guidelines for the use of EurekaTrip's platforms and services by Merchants, ensuring compliance and facilitating successful business operations.</p>
        </div>
        <Footer/>
        </>
    );
};

export default Terms;
