import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../../assets/css/themes.css';
import { useNavigate } from 'react-router-dom';

const Themes = () => {
    const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
    const [themes, setThemes] = useState([]);
    const navigate = useNavigate();  // Get the navigate function from useNavigate hook

    useEffect(() => {
       fetchThemes();
    }, []);
    const fetchThemes = async () => {
        try {
            const response = await fetch(`${BaseAPI}/tour/all/themes`);
            if (response.ok) {
                const data = await response.json();
                setThemes(data.data);
                console.log("data", data.data);
            } else {
                console.error('Failed to fetch themes:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching themes:', error);
        }
    };

    const handleCardClick = (themeName) => {
      console.log("themeName",themeName)

        if(themeName == "Experience"){
            navigate("/experience");
        }

        if (themeName === "Experience") {
            navigate("/experience");
        } else if (themeName === "Adventure") {
            navigate("/adventure");
        } else if (themeName === "Spiritual") {
            navigate("/spiritual");
        } else if (themeName === "Transformation") {
            navigate("/transformation");
        } else {
          
            navigate("/");
        }
   
    };


    return (
        <Container fluid className='custom-container h-auto  px-5 mt-4'>
          
            <Row>
                {themes.map((theme) => (
                    <Col xs={12} sm={6} md={4} lg={3} key={theme.themeId} className='mb-4'>
                        <Card className="h-100" onClick={() => handleCardClick(theme.themeName)}>
                            <div className="card-img-container">
                                <Card.Img
                                    variant="top"
                                    src={theme.imageUrl}
                                    className="img-fluid "
                                />
                            </div>
                            <Card.Body className="d-flex flex-column">
                                <span className="adventure-title text-center">
                                    {theme.themeName}
                                    
                             </span>
                                <div className='discription-font'>
                                    {theme.themeDesc}
                                    </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Themes;
