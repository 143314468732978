import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
  NavDropdown,
} from "react-bootstrap";
import { useAuth } from "../../../context/Authcontext";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../assets/css/navbar.css";
import userImg from "../../../assets/image/UserImg.png";
import { toast } from "react-toastify";
import UpdateProfile from "../../Login/UpdateProfile";
import LoginPopup from "../../Login/LoginPopup";
import eurekaImg from "../../../assets/image/EurekaTrip.png";
import adventureIcon from "../../../assets/image/tourism.png";
import transformationIcon from "../../../assets/image/data-transformation (1).png";
import experienceIcon from "../../../assets/image/talent-management.png";
import spiritualIcon from "../../../assets/image/mandala.png";

const NavbarHome = ({ pageName, adventureNavbar }) => {
  const [activeTab, setActiveTab] = useState(pageName || null);
  const [isMobileVisible, setIsMobileVisible] = useState(false);
  const [showFullName, setShowFullName] = useState(false);
  const [handleUpdatePopup, setHandleUpdatePopup] = useState(false);
  const [handleLoginPopup, setHandleLoginPopup] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showforgotpassword, setshowforgotpassword] = useState(false);
  const [showAdventureNavbar, setShowAdventureNavbar] = useState(false);
  const [showCategories, setShowCategories] = useState([]);
  const [CategoryId, setCategoryId] = useState();
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const [userData, setUserData] = useState({});
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const { user, logout } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path || activeTab === path;
  };

  useEffect(() => {
    fetchCategories();
    setUserData(user);

    const handleResize = () => {
      console.log("window.innerWidth", window.innerWidth);
      console.log("window.innerWidth <= 768", window.innerWidth <= 768);
      window.innerWidth <= 768
        ? setIsMobileVisible(true)
        : setIsMobileVisible(false);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [user]);

  const handleNameClick = () => {
    setShowFullName(!showFullName);
  };

  const toggleLoginFormVisibility = () => {
    setHandleLoginPopup(true);
    setShowRegisterForm(false);
  };

  const closeLoginForm = () => {
    setHandleLoginPopup(false);
    setShowRegisterForm(false);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
    window.location.reload();
    // toast.success("User logout success");
  };

  const handleLoginSuccess = () => {
    closeLoginForm();
    navigate("/");
  };
  const handleMouseEnter = () => {
    setShowAdventureNavbar(true);
  };

  const handleMouseLeave = () => {
    setShowAdventureNavbar(false);
  };
  const handleMouseAdventureLeave = () => {
    setShowAdventureNavbar(true);
  };
  const handleMyTourPage = () => {
    navigate("/booking-details");
  };
  const handleMyWishlist = () => {
    navigate("/whishlist-tour");
  };

  const handleUpdateProfile = () => {
    setHandleUpdatePopup(true);
  };

  const handleClose = () => setHandleUpdatePopup(false);

  const handleTabClick = (path) => {
    setActiveTab(path);
    navigate(path);
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/categories`);
      const data = await response.json();
      console.log("category==>", data.data);
      setShowCategories(data.data);
      console.log("data.data[0].categoryId", data.data[0].categoryId);
      setCategoryId(data.data[0].categoryId);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
    }
  };

  // const handleCategoryClick = ( categoryId, categoryName) => {
  //   console.log("navbar==>",categoryName)

  //   // setActiveTab(categoryId);

  //   navigate(`/adventure/${categoryId}`);
  //   // setShowAdventureNavbar(false);

  // };

  const handleCategoryClick = (categoryId) => {
    setActiveCategoryId(categoryId);
    navigate(`/adventure`, { state: { categoryId } });
  };
  const handleMouseEnterAdventure = () => {
    setShowAdventureNavbar(true);
  };

  const handleMouseLeaveAdventure = () => {
    setShowAdventureNavbar(false);
  };

  return (
    <>
      {!isMobileVisible && (
        <div className="fixed-top">
          <Row>
            <Col xs={12}>
              <Navbar
                variant="dark"
                expand="lg"
                className="navbar-custom"
                fixed="top"
              >
                <Container fluid>
                  <Navbar.Brand
                    className="brand-custom ms-2"
                    onClick={() => navigate("/")}
                  >
                    <span className="eurekatrip-text">
                      <img className="eureka-Logo" src={eurekaImg}></img>
                    </span>
                  </Navbar.Brand>
                  {/* <Navbar.Toggle aria-controls="basic-navbar-nav " /> */}
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto nav-links-custom">
                      <Nav className="themes ">
                        <Nav.Link
                          onClick={() => handleTabClick("/adventure")}
                          className={
                            isActive("/adventure") ? "active-nav-tab" : ""
                          }
                        >
                          <span className="adventure-text">Adventure</span>
                        </Nav.Link>

                        <Nav.Link
                          onClick={() => handleTabClick("/transformation")}
                          onMouseLeave={handleMouseLeave}
                          className={
                            isActive("/transformation") ? "active-nav-tab" : ""
                          }
                        >
                          <span className="transformation-text">
                            Transformation
                          </span>
                        </Nav.Link>
                        <Nav.Link
                          onClick={() => handleTabClick("/experience")}
                          onMouseLeave={handleMouseLeave}
                          className={
                            isActive("/experience") ? "active-nav-tab" : ""
                          }
                        >
                          <span className="transformation-text">
                            Experience
                          </span>
                        </Nav.Link>
                        <Nav.Link
                          onClick={() => handleTabClick("/spiritual")}
                          onMouseLeave={handleMouseLeave}
                          className={
                            isActive("/spiritual") ? "active-nav-tab" : ""
                          }
                        >
                          <span className="spiritual-text">Spiritual</span>
                        </Nav.Link>
                      </Nav>

                      <Nav>
                        <Button
                          variant=""
                          className="p-1 bg-white build-trip-button"
                          onClick={() => navigate("/personalizedTrip")}
                        >
                          <span className="trip-element">
                            Build Your AI Recommendation Trip
                          </span>
                        </Button>
                      </Nav>
                    </Nav>

                    <Nav>
                      {user ? (
                        <Navbar>
                          <Navbar.Toggle aria-controls="basic-navbar-nav " />
                          <Navbar.Collapse id="basic-navbar-nav">
                            <NavDropdown
                              title={
                                <span className="d-flex justify-content-center align-items-center">
                                  {/* <span className="circle-icon">
                                    {user?.firstName?.charAt(0)}
                                  </span> */}
                                  <span className="circle-icon">
                                    {user?.firstName
                                      ? user.firstName.charAt(0)
                                      : ""}
                                  </span>
                                </span>
                              }
                              id="navbarScrollingDropdown"
                              className="me--4 "
                              align="end"
                            >
                              <NavDropdown.Item onClick={handleUpdateProfile}>
                                My Account
                              </NavDropdown.Item>
                              <NavDropdown.Item onClick={handleMyTourPage}>
                                My Tour
                              </NavDropdown.Item>
                              <NavDropdown.Item onClick={handleMyWishlist}>
                                My Wishlist
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/" onClick={handleLogout}>
                                Logout
                              </NavDropdown.Item>
                            </NavDropdown>
                          </Navbar.Collapse>
                        </Navbar>
                      ) : (
                        <Nav.Link
                          as="div"
                          className="text-light ms-4"
                          style={{ cursor: "pointer" }}
                          onClick={toggleLoginFormVisibility}
                        >
                          <img
                            className="mt-1 profile-icon"
                            src={userImg}
                            alt=""
                          />
                        </Nav.Link>
                      )}
                      <Nav>
                        <Button
                          variant=""
                          className="p-1 mb-3 mt-3 me-3 ms-3 corporate-plan-button"
                          onClick={() =>
                            window.open(
                              "https://vendorhub.eurekatrip.com/",
                              "_blank",
                              "noopener,noreferrer"
                            )
                          }
                        >
                          <span className="corporate-plan">
                            Become A Vendor
                          </span>
                        </Button>
                      </Nav>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </Col>
          </Row>
        </div>
      )}

      {isMobileVisible && (
        <div className="fixed-top">
          <Row>
            <Col xs={12}>
              <Row className="nav-bar">
                <div>
                  <div className="d-inline">
                    <img
                      onClick={() => navigate("/")}
                      className="eureka-Logo"
                      src={eurekaImg}
                    ></img>
                  </div>

                  <div className="float-end me-1 mt-1">
                    {user ? (
                      <NavDropdown
                        title={
                          <span className="d-flex justify-content-center align-items-center profile-name">
                            {/* <span className="circle-icon">
                              {user.firstName.charAt(0)}
                            </span> */}
                            <span className="circle-icon">
                              {user?.firstName ? user.firstName.charAt(0) : "?"}
                            </span>
                          </span>
                        }
                        id="navbarScrollingDropdown"
                        className="me-1"
                        align="end"
                      >
                        <NavDropdown.Item onClick={handleUpdateProfile}>
                          My Account
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={handleMyTourPage}>
                          My Tour
                        </NavDropdown.Item>

                        <NavDropdown.Item onClick={handleMyWishlist}>
                          My Wishlist
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/" onClick={handleLogout}>
                          Logout
                        </NavDropdown.Item>
                      </NavDropdown>
                    ) : (
                      <img
                        className="profile-icon"
                        src={userImg}
                        alt=""
                        onClick={toggleLoginFormVisibility}
                      />
                    )}
                  </div>
                </div>
              </Row>

              <div className="floating-bottom-bar fixed-bottom">
                <div
                  className={
                    isActive("/adventure") ? "item item-active" : "item"
                  }
                  onClick={() => handleTabClick("/adventure")}
                >
                  <div>
                    <img src={adventureIcon} alt="" className="category-icon" />
                  </div>
                  <div className="item-name">Adventure</div>
                </div>

                <div
                  className={
                    isActive("/transformation") ? "item item-active" : "item"
                  }
                  onClick={() => handleTabClick("/transformation")}
                >
                  <div>
                    <img
                      src={transformationIcon}
                      alt=""
                      className="category-icon"
                    />
                  </div>
                  <div className="item-name">Transformation</div>
                </div>

                <div
                  className={
                    isActive("/experience") ? "item item-active" : "item"
                  }
                  onClick={() => handleTabClick("/experience")}
                >
                  <div>
                    <img
                      src={experienceIcon}
                      alt=""
                      className="category-icon"
                    />
                  </div>
                  <div className="item-name">Experience</div>
                </div>

                <div
                  className={
                    isActive("/spiritual") ? "item item-active" : "item"
                  }
                  onClick={() => handleTabClick("/spiritual")}
                >
                  <div>
                    <img src={spiritualIcon} alt="" className="category-icon" />
                  </div>
                  <div className="item-name">Spiritual</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
      {showAdventureNavbar && <div></div>}

      {!user && (
        <LoginPopup
          onLoginSuccess={handleLoginSuccess}
          showLoginPopup={handleLoginPopup}
          handleLoginClose={closeLoginForm}
          showRegisterForm={showRegisterForm}
          setShowRegisterForm={setShowRegisterForm}
          setHandleLoginPopup={setHandleLoginPopup}
          showforgotpassword={showforgotpassword}
          setshowforgotpassword={setshowforgotpassword}
        />
      )}
      {handleUpdatePopup && user && (
        <UpdateProfile
          showUpdatePopup={handleUpdatePopup}
          handleClose={handleClose}
          userData={user}
        />
      )}
    </>
  );
};

export default NavbarHome;
