import React, { useEffect, useRef, useState } from "react";
import Navbar from "../common/Navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Discover from "../common/Discover";
import Footer from "../../footer/footer";
import Recomandation from "../common/Recomandation";
import TourBooking from "../booked-tour/TourBooking";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Button, Modal, Row, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import MapView from "../schedule-tour/MapVeiw";
import { FaPlane, FaHiking } from "react-icons/fa";import "leaflet/dist/leaflet.css";
import "../../../assets/css/schedule-tours.css";
import durationImg from "../../../assets/image/durationImg.png";
import cancelImg from "../../../assets/image/cancellationIcon.png";
import linkImg from "../../../assets/image/link.png";
import messegeImg from "../../../assets/image/messenger.png";
import emailImg from "../../../assets/image/email.png";
import share from "../../../assets/image/share.png";

import he from "he";
import Calendar from "react-calendar";
import {
  GeoAlt,
  Bookmark,
  BookmarkFill,
  ArrowBarUp,
  Whatsapp,
  Envelope,
} from "react-bootstrap-icons";
import ScheduleItinerary from "../schedule-tour/ScheduleItinerary";

const BookedTourPage = ({ bookedTourId, isTourBooked }) => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [wishlist, setWishlist] = useState(true);

  const location = useLocation();
  const {
    tourId,
    pageName,
    BookedScheduledId,
    selectedCategory,
    selectedSubCategory,
    selectedThemeName,
    selectedThemeId,
    numberOfParticipants,
    amount,
    bookingDate
  } = location.state || {};

  const linkToCopy = `http://localhost:3000/#/tour/${tourId}`;
  const [veiwImagesPopup, setVeiwImagesPopup] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [tourData, setTourData] = useState({});
  const [scheduleId, setScheduleId] = useState();
  const [showBookPopup, setShowBookPopup] = useState(false);
  const [selectedTourId, setSelectedTourId] = useState();
  const [category, setCategory] = useState({});
  const [subCategory, setSubCategory] = useState({});
  const [mapUrl, setMapUrl] = useState("");
  const [selectedScheduleId, setSelectedScheduleId] = useState();
  const [taggedsubCategoryCategory, setTaggedsubCategoryCategory] = useState(
    []
  );
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [allThemes, setallThemes] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showFullHighlights, setShowFullHighlights] = useState(false);
  const [showFullAdditionalInfo, setShowFullAdditionalInfo] = useState(false);
  const [showFullIncluded, setShowFullIncluded] = useState(false);
  const [showFullNotIncluded, setShowFullNotIncluded] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const [formData, setFormData] = useState({ numberOfParticipants: "" });
  const [isMobileVisible, setIsMobileVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setshareModal] = useState(false);
  const [itinerary, setItinerary] = useState([]);

  const userData = JSON.parse(localStorage.getItem("user"));

  const mapRef = useRef(null);

  const highlightLimit = 200;
  const additionalInfoLimit = 200;
  const includedLimit = 50;
  const notIncludedLimit = 50;

  const [isSticky, setIsSticky] = useState(false);
  const [contentHeight, setContentHeight] = useState(0); // State for content height
  const scheduleRef = useRef(null);
  const bottomObserverRef = useRef(null);
  const topObserverRef = useRef(null);
  const additionalInfoRef = useRef(null);
  const stickyHeaderRef = useRef(null);



  const highlightContent = showFullHighlights
    ? tourData?.highlights
    : tourData?.highlights?.substring(0, highlightLimit) + "...";
  // const [shouldShowLearnMore, setshouldShowLearnMore] = useState(false);

  const additionalInfoContent = showFullAdditionalInfo
    ? tourData?.additionalInformation
    : tourData?.additionalInformation?.substring(0, additionalInfoLimit) +
      "...";

  const includedContent = showFullIncluded
    ? tourData?.included
    : tourData?.included?.substring(0, includedLimit);

  const notIncludedContent = showFullNotIncluded
    ? tourData?.notIncluded
    : tourData?.notIncluded?.substring(0, notIncludedLimit);

  // const shouldShowLearnMore =
  //   tourData.description && tourData.description.length > 150;

  const getShortDescription = (text) => {
    if (!text) return "";
    return text.length > 150 ? text.substring(0, 150) : text;
  };
  const shouldShowLearnMore = (text) => {
    // const textWithoutSpaces = text?.replace(/\s+/g, ''); // Remove all spaces

    return text?.length > 200; // Returns true if the text length exceeds 150 characters
  };

  const handleMediaSelect = (media) => {
    setSelectedMedia(media);
  };

  const videos =
    tourData.tourAssets?.filter((asset) => asset.assetType === "VIDEO") || [];

  useEffect(() => {
    window.scrollTo(0, 0);
    if(BookedScheduledId){
    fetchScheduleItinerary(BookedScheduledId)
    }
    const handleResize = () => {
      console.log("window.innerWidth", window.innerWidth);
      console.log("window.innerWidth <= 768", window.innerWidth <= 768);
      window.innerWidth <= 768
        ? setIsMobileVisible(true)
        : setIsMobileVisible(false);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const hash = window.location.hash;
    const paramTourId = hash.split("/")[2];

    if (paramTourId) {
      setSelectedTourId(paramTourId);
    }
    if (bookedTourId) {
      setSelectedTourId(bookedTourId);
    } else if (tourId) {
      setSelectedTourId(tourId);
    }
  }, [bookedTourId, tourId]);

  useEffect(() => {
    if (selectedTourId) {
      fetchTourData();
    }
  }, [selectedTourId]);

  const handleClose = () => setShowBookPopup(false);

  useEffect(() => {
    if (tourData.schedules && tourData.schedules.length > 0) {
      setStartDate(new Date(tourData.schedules[0].scheduleDate));
    }
  }, [tourData.schedules]);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  useEffect(() => {
    let previousScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const topOffset = topObserverRef.current?.offsetTop || 0;
      const bottomOffset =
        (bottomObserverRef.current?.offsetTop || 0) -
        (stickyHeaderRef.current?.offsetHeight || 0);
      const additionalInfoOffset = additionalInfoRef.current?.offsetTop || 0;

      if (
        currentScrollY > topOffset &&
        currentScrollY < bottomOffset &&
        currentScrollY < additionalInfoOffset
      ) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      previousScrollY = currentScrollY;
    };

    const debouncedHandleScroll = debounce(handleScroll, 50);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, []);

  

  const fetchTourData = async () => {
    try {
      //const response = await fetch(`${BaseAPI}/tour/${selectedTourId}`);

      const endpoint = userData?.userId
        ? `/tour/${selectedTourId}/user/${userData?.userId}`
        : `/tour/${selectedTourId}`;
      const response = await fetch(`${BaseAPI}${endpoint}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("data===>", data);
      setWishlist(data.isFavourite);
      const scheduleId =
        data.data.schedules?.length > 0
          ? data.data.schedules[0].schedule_id
          : null;
      console.log("scheduleId", scheduleId);
      setScheduleId(scheduleId);
      setTourData(data.data);
      setSchedules(data.data.schedules);
      console.log("data.data.schedules", data.data.schedules);
      const images = data.data.tourAssets.filter(
        (asset) => asset.assetType === "IMAGE"
      );
      setImages(images);

      const tourThemes = data.data.tourTheme || [];

      if (selectedCategory && selectedSubCategory) {
        const filterTaggedCategoryAndSubcategory = tourThemes.filter(
          (theme) =>
            theme.category?.categoryId !== selectedCategory ||
            theme.subCategory?.subCategoryId !== selectedSubCategory
        );
        setTaggedsubCategoryCategory(filterTaggedCategoryAndSubcategory);

        const filteredThemes = tourThemes.filter(
          (theme) =>
            theme.category?.categoryId === selectedCategory &&
            theme.subCategory?.subCategoryId === selectedSubCategory
        );

        if (filteredThemes.length > 0) {
          setCategory(filteredThemes[0].category);
          setSubCategory(filteredThemes[0].subCategory);
        }
      } else {
        const filterTaggedCategoryAndSubcategory = tourThemes.filter(
          (theme) => theme.theme?.themeId !== selectedThemeId
        );

        setTaggedsubCategoryCategory(filterTaggedCategoryAndSubcategory);
      }

      if (images.length > 0) {
        handleMediaSelect(images[0]);
        setSelectedMedia(images[0]);
      }

      const startDate =
        data.data.schedules.length > 0
          ? new Date(data.data.schedules[0]?.scheduleDate)
          : new Date();
      setStartDate(startDate);

      const locationData = data.data.location;

      if (locationData && locationData.latitude && locationData.longitude) {
        setMapUrl({
          latitude: parseFloat(locationData.latitude),
          longitude: parseFloat(locationData.longitude),
        });
      } else {
        setMapUrl(null);
      }
    } catch (error) {
      console.error("Error fetching tour data:", error.message);
    }
  };

  const handleBookNow = () => {
    console.log("formData", formData);
    const Participants = formData.numberOfParticipants;
    // setShowBookPopup(true);
    navigate("/checkout-pay", {
      state: {
        tourId,
        selectedSchedule: selectedSchedule,
        Participants: Participants,
      },
    });
  };

  // const openVideoModel = (selectedVideo) => {
  //   alert("video selected");
  //   console.log("selected video==>", selectedVideo);
  // };

  const handleViewMore = () => {
    setShowFullHighlights(true);
  };

  const handleViewLess = () => {
    setShowFullHighlights(false);
  };

  const handleViewMoreInfo = () => {
    setShowFullAdditionalInfo(true);
  };

  const handleViewLessInfo = () => {
    setShowFullAdditionalInfo(false);
  };

  const handleViewMoreIncluded = () => {
    setShowFullIncluded(true);
  };

  const handleViewLessIncluded = () => {
    setShowFullIncluded(false);
  };

  const handleViewMoreNotIncluded = () => {
    setShowFullNotIncluded(true);
  };

  const handleViewLessNotIncluded = () => {
    setShowFullNotIncluded(false);
  };

  const handleLearnMore = () => {
    setShowModal(true);
  };

  const handlePopupClose = () => {
    setShowModal(false);
  };

  const handleScheduleDateClick = (schedule) => {
    setShowParticipants(true);
    console.log("schedule.scheduleId", schedule.scheduleId);
    setSelectedScheduleId(schedule?.scheduleId);
    setSelectedSchedule(schedule);
  };

  const renderHighlights = (highlights) => {
    if(highlights){
    const decodedHighlights = he?.decode(highlights);
    const lines = decodedHighlights.split("\n");

    return lines.map((line, index) => (
      <div key={index} 
      className="text-gray-700 dark:text-gray-400 font-feature-tour"

      dangerouslySetInnerHTML={{ __html: line }}></div>
    ));
  }
  };

  const renderIncluded = (included) => {
    const lines = included?.split("\n");
    //   const decodedHighlights = he.decode(included);
    //  const lines = decodedHighlights.split("\n");
    return lines?.map((line, index) => (
      <React.Fragment key={index}>
        <li>
          <span className="">{line}</span>
        </li>
      </React.Fragment>
    ));
  };



  const veiwGallery = () => {
    setVeiwImagesPopup(true);
  };
  const closeGallery = () => {
    setVeiwImagesPopup(false);
  };

  const veiwSharePopup = () => {
    setshareModal(true);
  };
  const closeSharePopup = () => {
    setshareModal(false);
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClick = () => {
    if (mapRef.current) {
      mapRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const addToWishlist = async () => {
    if (!userData?.userId) {
      alert("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };
    console.log("data", data);
    // try {
    const response = await fetch(`${BaseAPI}/tour/save/favTour`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Failed to add to wishlist: ${response.statusText}`);
    }

    const responseData = await response.json();

    if (responseData) {
      setWishlist(false);
      toast.success("Added to your Wishlist");
    }
    // } catch (error) {
    //   alert(`Error: ${error.message}`);
    // }
  };

  const removeFromWishlist = async () => {
    if (!userData?.userId) {
      alert("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/delete/fav-tour`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to remove from wishlist: ${response.statusText}`
        );
      }

      const responseData = await response.json();

      if (responseData) {
        setWishlist(true);
        toast.success("Successfully removed from wishlist!");
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        alert("Link copied to clipboard!"); // You can replace this with a more subtle notification
      })
      .catch((err) => {
        console.error("Failed to copy the link: ", err);
      });
  };
  const handleWhatsAppShare = () => {
    const message = "Check out this tour: "; // Customize your message
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      message + linkToCopy
    )}`;
    window.open(whatsappUrl, "_blank"); // Open WhatsApp link in a new tab
  };
  const handleEmailShare = () => {
    const subject = "Interesting Tour Information"; // Customize your email subject
    const body = `Hi, \n\nCheck out this tour: ${linkToCopy} \n\nBest regards,`; // Customize your email body
    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink; // Opens the default email client
  };
  const handleSMS = () => {
    // You might need to adjust the message text as required
    const message = `Check out this great tour: ${linkToCopy}`;
    // This will attempt to open the default SMS app with a pre-filled message
    const smsLink = `sms:?body=${encodeURIComponent(message)}`;
    window.location.href = smsLink; // Opens the default SMS application
  };
  
  const fetchScheduleItinerary = async (scheduleId) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/itineraries/schedule/${scheduleId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setItinerary(data.data);
      console.log("fetchScheduleItinerary======>",data.data)
    } catch (error) {
      console.error("Error fetching tour data:", error.message);
    }
  };

  const DayItem = ({ day, icon, description, isLast }) => (
    <div className="d-flex position-relative align-items-center mb-3">
      <div className="d-flex flex-column align-items-center day-indicator">
        <div className="day-number">{day}</div>
        <div className="day-label">DAY</div>
        {!isLast && <div className="dot-line"></div>}
      </div>
      <div className="mx-3">
        {icon === "plane" ? (
          <FaPlane size={30} className="text-primary" />
        ) : (
          <FaHiking size={30} className="text-success" />
        )}
      </div>
      <div className="flex-grow-1">
        <div className="fw-bold">{description}</div>
      </div>
    </div>
  );
  
  return (
    <>
      <Navbar pageName={pageName} />

      <div className="trip-page-container h-auto">
        <div className="main-container mb-3">
          <div className="container d-flex gap-5 ">
            {selectedThemeName && (
              <div className="text-style-schedule font-medium ">
                {selectedThemeName}
                {category && category?.categoryName && (
                  <span>/{category?.categoryName}</span>
                )}
                {subCategory && subCategory?.subCategoryName && (
                  <span>/{subCategory?.subCategoryName}</span>
                )}
              </div>
            )}

            {taggedsubCategoryCategory.map((theme, index) => (
              <span className="badge font-medium px-0 mb-2" key={index}>
                {theme.theme.themeName}
                {theme.category?.categoryName && (
                  <span>/ {theme.category?.categoryName}</span>
                )}
                {theme.subCategory?.subCategoryName && (
                  <span>/{theme.subCategory?.subCategoryName}</span>
                )}
              </span>
            ))}
          </div>

          {/* <div className="container">
         
            <p className="schedule-main-description mb-4">
              {tourData.shortDescription}
            </p>
          </div> */}
        </div>

        <div className="mx-3 mx-lg-5">
          {images.length === 2 ? (
            <Row>
              {images
                .filter(
                  (asset) =>
                    asset.assetCategory === "FEATURED" ||
                    asset.assetCategory === "SNAPSHOTS"
                )
                .map((asset, index) => (
                  <Col key={index} md={6}>
                    <img
                      src={asset.assetUrl}
                      alt={`Image ${index}`}
                      className="img-fluid mt-3"
                      style={{
                        width: "100%",
                        height: "85%",
                        borderRadius: "13px",
                      }}
                    />
                  </Col>
                ))}
            </Row>
          ) : (
            <Row>
              <Col md={6}>
                <img
                  src={images[0]?.assetUrl}
                  alt="Main Image"
                  className="feature-schedule-image"
                />
                <div
                  className=""
                  style={{
                    position: "relative",
                    bottom: "50px",
                    right: "10px",
                  }}
                >
                  <button className="btn btn-share btn-outline-white ms-3">
                    {/* <Bookmark /> Save */}
                    {!wishlist && (
                      <div onClick={() => removeFromWishlist()}>
                        <BookmarkFill style={{ color: "white" }} />
                        {!isMobileVisible && (
                          <span className="ms-1">Saved</span>
                        )}
                      </div>
                    )}
                    {wishlist && (
                      <div onClick={() => addToWishlist()}>
                        <Bookmark />
                        {!isMobileVisible && (
                          <span className="ms-1"> Save</span>
                        )}
                      </div>
                    )}
                  </button>
                  <button
                    onClick={veiwSharePopup} // Corrected typo in function name
                    className="btn btn-share  btn-outline-white ms-3"
                  >
                    <img
                      src={share}
                      className="mb-1"
                      style={{
                        width: "20px",
                        height: "20px",
                        filter: "brightness(0) invert(1)",
                      }}
                    />
                    {!isMobileVisible && <span> Share</span>}
                  </button>
                </div>
              </Col>
              <Col md={6}>
                {!isMobileVisible && (
                  <div>
                    <Row>
                      {images.slice(0, 4).map((asset, index) => (
                        <Col key={index} md={6} className="mb-2 ps-0">
                          <div
                            style={{
                              height: "210px",
                              width: "100%",
                              overflow: "hidden",
                              borderRadius: "10px",
                              marginBottom: "0px",
                            }}
                          >
                            <img
                              src={asset.assetUrl}
                              alt={`Image ${index + 1}`}
                              className="img-fluid"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                marginTop: "0px",
                              }}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                )}
                <div className="float-end veiw-Gallery">
                  <button
                    className="btn  btn-share btn-outline-white view-gallery-btn"
                    onClick={veiwGallery}
                  >
                    {!isMobileVisible && (
                      <span>View Gallery ({images.length})</span>
                    )}
                    {isMobileVisible && (
                      <div className="gallary-svg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-[20px] stroke-white md:hidden"
                          stroke="currentColor"
                          aria-hidden="true"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          data-phx-id="m23-phx-F9pMhI4iBEhJTREB"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                          ></path>
                        </svg>
                      </div>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col></Col>
            <Col></Col>
          </Row>
        </div>

        <Modal show={veiwImagesPopup} onHide={closeGallery} size="xl">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Row>
              {images.map((asset, index) => (
                <Col key={index} md={6} className="mb-4">
                  <img
                    src={asset.assetUrl}
                    alt={`Image ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "300px",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                </Col>
              ))}
            </Row>
          </Modal.Body>
        </Modal>

        <div className="trip-content ">
          <div className="trip-details-main">
            <div className=" mx-3 ms-md-5">
              <div className="mb-2">
                <span className="font-schedule-title text-wrap ">
                  {tourData.title}
                </span>
              </div>

              {/* <div>
                <span className="mt-2">
                  <img
                    className="duration-icon mb-2"
                    src={durationImg}
                    alt=""
                  />
                  <span className="duration-day-info ms-2  ">
                    <span className="font-duration-medium"> Duration :</span>
                    <span className=" text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-days-tour">
                      {tourData.duration} Day
                    </span>
                  </span>
                </span>
              </div> */}

              <div
                className="mt-3"
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              >
                <GeoAlt className="text-muted font-duration-medium me-2" />
                <span className="font-medium">Pune, India</span>
              </div>

              <hr></hr>

              <div className="prose">
                <span className="text-gray-700 dark:text-gray-400 font-feature-tour text-wrap">
                  {renderHighlights(tourData.description)}
                </span>
                {shouldShowLearnMore(tourData?.description) && (
                  <Button
                    className="view-btn"
                    variant="link"
                    onClick={handleLearnMore}
                  >
                    Learn More
                  </Button>
                )}

                {/* Modal to show full description */}
                <Modal show={showModal} onHide={handlePopupClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title className="highlights-text">
                      Description
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="text-gray-700 dark:text-gray-400 font-feature-tour">
                    {renderHighlights(tourData.description)}
                  </Modal.Body>
                  <Modal.Footer>
                    {/* Optional: Close button in modal footer */}
                    {/* <Button variant="secondary" onClick={handlePopupClose}>
            Close
           </Button> */}
                  </Modal.Footer>
                </Modal>
              </div>

              <div className="mt-4">
                <span className="highlights-text">Highlights </span>
                <div>
                  <p className="mt-1">
                    <span className="text-gray-700 dark:text-gray-400 font-feature-tour text-wrap">
                      {renderHighlights(highlightContent)}
                      {!showFullHighlights &&
                        tourData?.highlights?.length > highlightLimit && (
                          <Button
                            className="view-btn"
                            variant="link"
                            onClick={handleViewMore}
                          >
                            see more
                          </Button>
                        )}
                      {showFullHighlights && tourData?.highlights && (
                        <Button
                          className="view-btn"
                          variant="link"
                          onClick={handleViewLess}
                        >
                          see less
                        </Button>
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="trip-details mx-3 ms-md-5">
              <div>
                <span className="highlights-text">What's Included</span>
                <ul className="list-unstyled ">
                  <li >
                    <span className="text-gray-700 dark:text-gray-400 font-feature-tour text-wrap">
                      {renderHighlights(includedContent)}
                      {!showFullIncluded &&
                        tourData?.included?.length > includedLimit && (
                          <Button
                            className="view-btn"
                            variant="link"
                            onClick={handleViewMoreIncluded}
                          >
                            see more
                          </Button>
                        )}
                      {showFullIncluded && tourData?.included && (
                        <>
                          <Button
                            className="view-btn"
                            variant="link"
                            onClick={handleViewLessIncluded}
                          >
                            see less
                          </Button>
                        </>
                      )}
                    </span>
                  </li>
                </ul>

                <span className="highlights-text">What's Not Included</span>
                <ul className="list-unstyled ">
                  <li>
                    <span className="text-gray-700 dark:text-gray-400 font-feature-tour text-wrap">
                      {renderHighlights(notIncludedContent)}
                      {!showFullNotIncluded &&
                        tourData?.notIncluded?.length > notIncludedLimit && (
                          <Button
                            className="view-btn"
                            variant="link"
                            onClick={handleViewMoreNotIncluded}
                          >
                            see more
                          </Button>
                        )}
                      {showFullNotIncluded && tourData?.notIncluded && (
                        <>
                          <Button
                            className="view-btn"
                            variant="link"
                            onClick={handleViewLessNotIncluded}
                          >
                            see less
                          </Button>
                        </>
                      )}
                    </span>
                  </li>
                </ul>
              </div>

       

               <div>
      <div>
        <span className="highlights-text">Location</span>
        <div className="media-map" ref={scheduleRef}>
          {mapUrl && <MapView locationData={mapUrl} />}
        </div>
      </div>

      {schedules && tourId &&  (
 <div className="media-map">
   <span className="font-schedule-title ms-2 mb-2">Detailed Itinerary</span>
        {itinerary?.map((item, index) => (
          <DayItem
            key={index}
            day={item.dayNumber}
            icon={item.iconUrl}
            description={item.description}
            isLast={index === itinerary.length - 1}
          />
        ))}
    </div>
      )}

      <div ref={additionalInfoRef} className="mt-4">

        <span className="highlights-text">Additional Information</span>
        <p>
          <span className="text-gray-700 dark:text-gray-400 font-feature-tour text-wrap">
            {renderHighlights(additionalInfoContent)}
            {tourData?.additionalInformation?.length > additionalInfoLimit && (
              <>
                {!showFullAdditionalInfo && (
                  <Button
                    className="view-btn"
                    variant="link"
                    onClick={handleViewMoreInfo}
                  >
                    see more
                  </Button>
                )}
                {showFullAdditionalInfo && (
                  <Button
                    className="view-btn"
                    variant="link"
                    onClick={handleViewLessInfo}
                  >
                    see less
                  </Button>
                )}
              </>
            )}
          </span>
        </p>
      </div>
    </div>

              <div>
                <span className="highlights-text">Cancellation Policy</span>
                <p>
                  <span className="text-gray-700 dark:text-gray-400 font-feature-tour">
                    {tourData?.cancellationPolicy?.policyName}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="booking-details me-5 d-none d-md-block ">
          
              <div >
                <span
                  className="tour-icon text-gray-700 dark:text-gray-400 font-feature-tour routes-btn"
                >
                  <span className="font-medium">
                <div  className="font-medium">     Number Of Participants:  {numberOfParticipants
                  }</div>

<div  className="font-medium">Amount Paid: ${amount}</div> </span>
               
                </span>
              </div>
    
    
    
          </div>
        </div>
      </div>

      <Modal show={showShareModal} onHide={closeSharePopup}>
        <Modal.Header closeButton>
          <Modal.Title className="highlights-text">
            Share this experience
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-2">
              <img
                src={images[0]?.assetUrl}
                alt="Main Image"
                className="scheduled-share-img"
                style={{ width: "52px", height: "52px", borderRadius: "10px" }}
              />
            </div>
            <div className="col-10">
              <span className="font-medium-title feature-title">
                {tourData.title}
              </span>
            </div>
          </Row>
          <Row className="mt-3">
            <Col>
              <div
                className="w-100 share-input-box d-flex align-items-center justify-content-center "
                variant="primary"
                role="button"
                onClick={handleCopyLink}
              >
                {/* <div className="link-svg mb-2">  <svg xmlns="http://www.w3.org/2000/svg" class="w-4 mr-2" stroke="currentColor" aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke-width="1.5" data-phx-id="m34-phx-F9pMhI4iBEhJTREB">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"></path>
                </svg>
                </div> */}

                <img
                  src={linkImg}
                  className=""
                  style={{
                    color: "",
                    width: "15px",
                    height: "15px",
                  }}
                />
                <span className="ms-2 prose">Copy Link</span>
              </div>
            </Col>
            <Col>
              <div
                className="w-100 share-input-box justify-content-center d-flex align-items-center justify-content-center"
                role="button"
                onClick={handleWhatsAppShare}
              >
                <Whatsapp />
                <span className="ms-2">WhatsApp</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <div
                className="w-100 share-input-box justify-content-center d-flex align-items-center justify-content-center"
                variant="primary"
                role="button"
                onClick={handleEmailShare}
              >
                <img
                  src={emailImg}
                  className=""
                  style={{
                    color: "black",
                    width: "15px",
                    height: "15px",
                  }}
                />
                <span className="ms-2 prose">Email</span>
              </div>
            </Col>
            <Col>
              <div
                role="button"
                className="w-100 share-input-box justify-content-center d-flex align-items-center justify-content-center"
                onClick={handleSMS}
              >
                {" "}
                <img
                  src={messegeImg}
                  className=""
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                />
                <span className="ms-2 prose">Message</span>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <div className="margin-discover">
        <Discover />
        <Recomandation />
        <Footer />
      </div>
    </>
  );
};

export default BookedTourPage;
