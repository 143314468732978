import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN } from './oAuth';

const OAuth2RedirectHandler = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserDataAndRedirect = async () => {
            const token = localStorage.getItem(ACCESS_TOKEN);
            if (!token) {
                navigate('/login');
                return;
            }

            const url = `${process.env.REACT_APP_PEEK_SERVER_ENDPOINT}/user/me`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                 navigate('/profile');
            } catch (error) {
                console.error("Fetch error: ", error);
                navigate('/login');
            }
        };

        const urlParams = new URLSearchParams(window.location.search);
        const error = urlParams.get('error');

        if (!error) {
            const token = urlParams.get('token');
            if (token) {
                localStorage.setItem(ACCESS_TOKEN, token);
                fetchUserDataAndRedirect();
            } else {
                navigate('/login');
            }
        }
    }, [navigate]);

   
};

export default OAuth2RedirectHandler;
