import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaPlane, FaHiking } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row } from "react-bootstrap";
import "../../../assets/css/schedule-tours.css";

const TrekOption = ({
  title,
  days,
  tourDays,
  isSelected,
  onClick,
  startDate,
  endDate,
}) => (
  <div
    className={`d-flex flex-column align-items-center ms-2 p-1 ${
      isSelected ? "text-success" : ""
    }`}
    onClick={onClick}
  >
    <div className="date-font mb-1">Start at</div>
    <div className="date-font mb-1">{startDate}</div>
    <div className="text-muted date-font">({tourDays}-day tour)</div>
    {isSelected && (
      <div className="border-bottom border-success w-100 mt-1"></div>
    )}
  </div>
);

const DayItem = ({ day, icon, description, isLast }) => (
  <div className="d-flex position-relative align-items-center mb-3">
    <div className="d-flex flex-column align-items-center day-indicator">
      <div className="day-number">{day}</div>
      <div className="day-label">DAY</div>
      {!isLast && <div className="dot-line"></div>}
    </div>
    <div className="mx-3">
      {icon === "plane" ? (
        <FaPlane size={30} className="text-primary" />
      ) : (
        <FaHiking size={30} className="text-success" />
      )}
    </div>
    <div className="flex-grow-1">
      <div className="itinerary-description  text-wrap">{description}</div>
    </div>
  </div>
);

const ScheduleItinerary = ({ schedules, tourId, tourTittle, isSticky }) => {
  const navigate = useNavigate();
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [itinerary, setItinerary] = useState([]);
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const [formData, setFormData] = useState({
    numberOfParticipants: "",
  });

  const stickyHeaderRef = useRef(null);
  const [isMobile, setIsMobile] = useState();

  const [startIndex, setStartIndex] = useState(0);
  const visibleItems = 3;
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      console.log("window.innerWidth", window.innerWidth);
      console.log("window.innerWidth <= 768", window.innerWidth <= 768);
      window.innerWidth <= 768
        ? setIsMobile(true)
        : setIsMobile(false);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
console.log("schedules",schedules)
    
    if (schedules && schedules.length > 0) {
      const initialSchedule = schedules[0];
      setSelectedSchedule(initialSchedule);
      fetchScheduleItinerary(initialSchedule.scheduleId);
    }
  }, [schedules]);

  const fetchScheduleItinerary = async (scheduleId) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/itineraries/schedule/${scheduleId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setItinerary(data.data);
    } catch (error) {
      console.error("Error fetching tour data:", error.message);
    }
  };

  const handleSelect = (schedule) => {
    setSelectedSchedule(schedule);
    fetchScheduleItinerary(schedule.scheduleId);
  };

  const handleBookNow = () => {
    console.log("formData", formData);
    const Participants = formData.numberOfParticipants;
    navigate("/checkout-pay", {
      state: {
        tourId,
        selectedSchedule: selectedSchedule,
        Participants: Participants,
      },
    });
  };

  // const handleBookNow = () => {
  //   console.log("formData", formData);
  //   const Participants = formData.numberOfParticipants;
  //   navigate("/checkout", {
  //     state: {
  //       tourId,
  //       selectedSchedule: selectedSchedule,
  //       Participants: Participants,
  //     },
  //   });
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePrevClick = () => {
    setStartIndex((prevIndex) => Math.max(prevIndex - visibleItems, 0));
  };

  const handleNextClick = () => {
    setStartIndex((prevIndex) =>
      Math.min(prevIndex + visibleItems, schedules.length - visibleItems)
    );
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className="rounded schedule-itinerary-main">
        <span className="font-schedule-title ms-2 mb-2">Detailed Itinerary</span>
        <div
          ref={stickyHeaderRef}
          className={`sticky-header d-flex justify-content-between  align-items-center mb-4 sticky-schedule ${
            isSticky ? "sticky" : ""
          }`}
          style={{ top: isSticky ? '100px' : 'auto', backgroundColor: isSticky ? 'white' : '' }}
        >
          <div
            className="d-flex flex-grow-1 justify-content-around font-medium-title"
            style={{ cursor: "pointer" }}
          >
            <div className="date-font text-success col-4 truncate-2-lines sticky-title">
              {selectedSchedule?.duration} {selectedSchedule?.durationTime} {selectedSchedule && selectedSchedule.scheduleTitle}
            </div>
          </div>
          {!isMobile && (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div>
                <Button
                  variant="primary"
                  onClick={handlePrevClick}
                  disabled={startIndex === 0}
                >
                  &lt;
                </Button>
              </div>
              <div className="d-flex flex-row mx-1 col-10 date-col">
                {schedules
                  .slice(startIndex, startIndex + visibleItems)
                  .map((schedule) => (
                    <TrekOption
                      key={schedule.scheduleId}
                      tourDays={schedule.duration}
                      startDate={schedule.startDate}
                      endDate={schedule.endDate}
                      isSelected={
                        selectedSchedule &&
                        selectedSchedule.scheduleId === schedule.scheduleId
                      }
                      onClick={() => handleSelect(schedule)}
                    />
                  ))}
              </div>
              <div>
                <Button
                  variant="primary"
                  onClick={handleNextClick}
                  disabled={startIndex >= schedules.length - visibleItems}
                >
                  &gt;
                </Button>
              </div>
            </div>
          )}
          {!isMobile && (
            <div className="col-3">
              <Form>
                <Form.Group>
                  <Row className="itinerary-btn">
                    <div className="ms-5">
                      <Form.Control
                        type="text"
                        name="numberOfParticipants"
                        className="ms-2 routes-btn custom-placeholder"
                        placeholder="Number Of Participants"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="ms-5 mt-2">
                      <Button className="book-button" onClick={handleBookNow}>
                        Book Me
                      </Button>
                    </div>
                  </Row>
                </Form.Group>
              </Form>
            </div>
          )}
        </div>
        {isMobile && (
          <>
            <div className="d-flex flex-row ms-2 align-items-center detailed-btn">
              <div>
                <Button
                  variant="primary"
                  onClick={handlePrevClick}
                  disabled={startIndex === 0}
                >
                  &lt;
                </Button>
              </div>
              <div className="d-flex flex-row mx-1 col-10 date-col text-wrap">
                {schedules
                  .slice(startIndex, startIndex + visibleItems)
                  .map((schedule) => (
                    <TrekOption
                      key={schedule.scheduleId}
                      tourDays={schedule.duration}
                      startDate={schedule.startDate}
                      endDate={schedule.endDate}
                      isSelected={
                        selectedSchedule &&
                        selectedSchedule.scheduleId === schedule.scheduleId
                      }
                      onClick={() => handleSelect(schedule)}
                    />
                  ))}
              </div>
              <div>
                <Button
                className="nextclick-btn"
                  variant="primary"
                  onClick={handleNextClick}
                  disabled={startIndex >= schedules.length - visibleItems}
                >
                  &gt;
                </Button>
              </div>
            </div>

            <div className="col-12 mt-3">
              <Form>
                <Form.Group>
                  <Row className="itinerary-btn">
                    <div className="d-flex">

                    <div className="ms-2 ">
                      <Form.Control
                        type="text"
                        name="numberOfParticipants"
                        className="ms-2 routes-btn custom-placeholder"
                        placeholder="Number Of Participants"
                        onChange={handleChange}
                      />
                    </div>
                    <div className=" mt-3 ">
                      <Button className="book-button" onClick={handleBookNow}>
                        Book Me
                      </Button>
                    </div>
                    </div>
                  </Row>
                </Form.Group>
              </Form>
            </div>

          </>
        )}
        <div className="scrollable-content font-medium-title text-wrap">
          {itinerary?.map((item, index) => (
            <DayItem
              key={index}
              day={item.dayNumber}
              icon={item.iconUrl}
              description={item.description}
              isLast={index === itinerary.length - 1}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ScheduleItinerary;
