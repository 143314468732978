import React from "react";
import Navbar from "../common/Navbar";
import relationshipImg from "../../../assets/image/relationship.png";
import Recomandation from "../common/Recomandation";
import Footer from "../../footer/footer";
import businessImg from'../../../assets/image/buisness.png';
import healthImg from '../../../assets/image/health.png';
import { useNavigate } from "react-router-dom";
import "../../../assets/css/personalized-tour.css"; 
import arrowIcon from '../../../assets/image/Icon.png';

const PersonalizedTrip = () => {

  const navigate = useNavigate();
  

  const handleCardClick = (path) => {
    navigate(path);
  };


  return (
    <>
      <Navbar />
      <div className="text-center" style={{ backgroundColor: '#D9E8FA' }}>
        <div className="row mb-4 ms-lg-5 mt-lg-5 d-grid justify-content-center">
          <div className="col personalized-tour">
            <div>
              <p className="  personalized-tour-text text-wrap">We will guide you build your personalized tour</p>
            </div>
            <p className="font-medium-title ms-3 text-wrap">
              Discover new facets of yourself while experiencing thrilling
              adventures tailored to your interests.
            </p>
            <p className="mt-2 mb-5 aspect-text text-wrap">
              Which aspect of your life are you looking to enrich through your
              travels?
            </p>
          </div>
        </div>

        <div className="row">
          <div className="d-flex justify-content-center flex-wrap gap-2 mb-5 category-img">
            <div className="card mx-2 mb-4 health-card">
              <span className="position-absolute mt-2">Health</span>
              <img className="position-absolute mt-2 " src={arrowIcon} alt="" style={{width:'24px', height:'24px', marginLeft:'100px'}} />

              <img
                src={healthImg}
                className="card-img-top custom-image"
                alt="Health"
                style={{ height: '300px' }}
                onClick={() => handleCardClick('/health-tour')}
              />
            </div>

            <div className="card mx-2 mb-4 relationship-card">
              <span className="position-absolute mt-2">Relationship</span>
              <img className="position-absolute mt-2 " src={arrowIcon} alt="" style={{width:'24px', height:'24px', marginLeft:'180px'}} />

              <img
                src={relationshipImg}
                className="card-img-top custom-image"
                alt="Relationship"
                style={{ height: '300px' }} 
                onClick={() => handleCardClick('/relationship-tour')}
              />
            </div>

            <div className="card mx-2 mb-4 business-card">
              <span className="position-absolute mt-2">Business</span>
              <img className="position-absolute mt-2 " src={arrowIcon} alt="" style={{width:'24px', height:'24px', marginLeft:'130px'}} />

              <img
                src={businessImg}
                className="card-img-top custom-image"
                alt="Business"
                style={{ height: '300px' }} 
                onClick={() => handleCardClick('/business-tour')}

              />
            </div>
          </div>
        </div>
      </div>
      <Recomandation/>
      <Footer/>
    </>
  );
};

export default PersonalizedTrip;
