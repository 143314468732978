import React, { useState ,useEffect} from "react";
import { Row, Col, Button } from "react-bootstrap";
import NavbarHome from "../common/Navbar";
import Recomandation from "../common/Recomandation";
import Footer from "../../footer/footer";
import { useNavigate } from "react-router-dom";
import SuggestTour from "../personalized-trip/SuggestTour";

import "../../../assets/css/health-tour.css";

const RelationshipTour = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedTab, setSelectedTab] = useState("");
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [suggestedTourData, setSuggestedTourData] = useState([]);
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const navigate = useNavigate();

  useEffect(() => {
    getQuestion();
  }, []);

  const getQuestion = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/questions/personalized-category/Relationship`);
      if (!response.ok) {
        throw new Error(`Error fetching all tours: ${response.statusText}`);
      }
      const data = await response.json();
      const fetchedQuestions = data.data.question.map((item) => ({
        question: item.question,
        answers: item.objective.map((obj) => obj.objective),
        description: item.questionType.name,
      }));
      setQuestions(fetchedQuestions);
      setAnswers(Array(fetchedQuestions.length).fill(""));
      setSelectedTab(fetchedQuestions[0]?.description || "");
    } catch (error) {
      console.error("Error fetching all tours:", error);
    }
  };

  const handleNext = () => {
    if (answers[currentQuestion] !== "") {
      if (currentQuestion < questions.length - 1) {
        const nextQuestion = currentQuestion + 1;
        setCurrentQuestion(nextQuestion);
        setSelectedTab(questions[nextQuestion].description);
      }
    } else {
      alert("Please answer the current question before moving to the next one.");
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      const previousQuestion = currentQuestion - 1;
      setCurrentQuestion(previousQuestion);
      setSelectedTab(questions[previousQuestion].description);
    }
  };

  const handleAnswer = (answer) => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestion] = answer;
    setAnswers(updatedAnswers);
  };

  // const handleSubmit = () => {
  //   console.log("Submitted answers:", answers);
  //   alert("Thank you for your responses!");
  //   navigate('/');
  // };



  const handleSubmit = async (e) => {
    console.log("Submitted answers:", answers);
   
    const data = {
      "personalizeCategoryName": "Relationship",
      "objectives":answers
    }
  

    e.preventDefault();
    try {
       const response = await fetch(
        `${BaseAPI}/tour/suggest-tours`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update tour");
      }
      const responseData = await response.json();
      setSuggestedTourData(responseData.data);

      console.log("suggestedTourData ===>",suggestedTourData)
      alert("Question data submited")

      console.log("Response data:", responseData)
    } catch (error) {
      console.error("Error updating tour:", error);
    }
  };

  const [businessData, setBusinessData] = useState([]);

  return (
    <>
      <div>
        <NavbarHome />
      </div>
      {suggestedTourData.length == 0 &&
      <div className="d-flex flex-column align-items-center health-main">
        <Row className="text-center health-text-main">
          <Col>
            <span className="health-title mt-lg-5">
              Help us match you to the right business tour
            </span>
            <p className="main-description ms-4">
              It's important to have a therapist who you can establish a
              personal connection with. The following questions are designed to
              match you to a licensed therapist based on your therapy needs and
              personal preferences.
            </p>
          </Col>
        </Row>

        <Row>
          <div className="tabs mt-5">
            {questions.map((question, index) => (
              <button
                key={index}
                className={`tab-btn ${selectedTab === question.description ? "active" : ""}`}
                onClick={() => setSelectedTab(question.description)}
              >
                {question.description}
              </button>
            ))}
          </div>
        </Row>

        <Row className="justify-content-center">
          <Col md={8} lg={6} className="health-questions mb-4">
            <div>
              <div>
                <span className="health-tour-text text-center mb-5">
                  Personalized Business Tour
                </span>
              </div>

              <div>
                <div controlId={`question-${currentQuestion}`}>
                  <div className="health-tour-questions mb-5 mt-3">
                    <span className="main-questions">
                      {questions[currentQuestion]?.question}
                    </span>
                  </div>

                  <div className="d-flex flex-column">
                    {questions[currentQuestion]?.answers.map((answer, index) => (
                      <Button
                        key={index}
                        variant={
                          answers[currentQuestion] === answer ? "primary" : "outline-primary"
                        }
                        onClick={() => handleAnswer(answer)}
                        className="mb-2 ans-btn"
                      >
                        {answer}
                      </Button>
                    ))}

                    <div className="text-muted mb-5">
                      {/* <span className="health-text">
                        {questions[currentQuestion]?.description}
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="d-flex gap-3 ps-5 ms-5 next-btn">
                <Button
                  variant="primary"
                  onClick={handlePrevious}
                  disabled={currentQuestion === 0}
                >
                  Previous
                </Button>
                {currentQuestion === questions.length - 1 ? (
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={answers[currentQuestion] === ""}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={handleNext}
                    disabled={answers[currentQuestion] === ""}
                  >
                    Next
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
}

{suggestedTourData.length > 0  && <SuggestTour suggestedTourData={suggestedTourData} />}
      <Recomandation />
      <Footer />
    </>
  );
};

export default RelationshipTour;
