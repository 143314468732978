import { Button } from "react-bootstrap";
import React from "react";
import Navbar from '../pages/common/Navbar';
import Footer from "./footer";
const Delete = () => {
    return(
        <>
        <Navbar/>
        <div className="container mt-5 pt-5 ">
        <Button variant="danger" style={{width:"100px",height:"50px",marginLeft:"45%",marginTop:"10px"}}>Delete</Button>
        </div>
        <div className="container" style={{marginTop:"300px"}}></div>
        <Footer/>
        </>
    )
}
export default Delete;