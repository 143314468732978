import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import Navbar from "../common/Navbar";
import Footer from "../../footer/footer";
import CustomerInformation from "./CustomerInformation";
import PaymentInformation from "./PaymentInformation";
import eurekaImg from "../../../assets/image/EurekaTrip.png";

import "../../../assets/css/checkout.css";
import { Building, People, Clock, BagX } from "react-bootstrap-icons";

const Checkout = ({ transactionData }) => {
  const location = useLocation();
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const [tourData, setTourData] = useState({});
  const [transactionScheduleId, setTransactionScheduleId] = useState();
  const { tourId } = location.state || {};
  const [selectedSchedule, setSelectedSchedule] = useState(
    location.state?.selectedSchedule
  );
  const [Participants, setParticipants] = useState(
    location.state?.Participants
  );
  const [tourImage, setTourImage] = useState(null);
  const [Schedules, setSchedules] = useState([]);
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user"));

  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    phoneNumber: "",
  });

  const [currentStep, setCurrentStep] = useState(1);

  const totalPrice =
    Participants * (selectedSchedule ? selectedSchedule.price : 0);

  useEffect(() => {
    if (userData) {
      setFormData({
        email: userData.email || "",
        fullName: userData.firstName || "",
        phoneNumber: userData.mobileNumber || "",
      });
    }
  }, [userData]);

  useEffect(() => {
    if (tourId) {
      fetchTourData(tourId);
    }
  }, [tourId]);

  const fetchTourData = async (id) => {
    try {
      const response = await fetch(`${BaseAPI}/tour/${id}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTourData(data.data);
      if (data.data) {
        setSchedules(data.data.schedules);
      }

      if (data.data.tourAssets && data.data.tourAssets.length > 0) {
        setTourImage(data.data.tourAssets[0].assetUrl);
      }
      if (transactionData) {
      }
    } catch (error) {
      console.error("Error fetching tour data:", error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const guestUserData = {
        email: formData.email,
        fullName: formData.fullName,
        mobileNumber: formData.phoneNumber,
      };
      const response = await fetch(`${BaseAPI}/tour/save/guestUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(guestUserData),
      });
      if (!response.ok) {
        throw new Error("Failed to update tour");
      }
      const responseData = await response.json();
      const userId =
        responseData.data.guestUserId == null
          ? responseData.data.userId
          : responseData.data.guestUserId;
      handlePayment(userId);
    } catch (error) {
      console.error("Error updating tour:", error);
    }
  };

  const handlePayment = async (userId) => {
    const bookData = {
      numberOfParticipants: Participants,
      status: "CONFIRMED",
    };
  
    try {
      const response = await fetch(
        `http://192.168.1.36/razorpay/pay/user/${userId}/schedule/${selectedSchedule.scheduleId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bookData),
        }
      );
  
      const data = await response.json();
  
      if (response.ok) {
        if (data.key && data.amount && data.order_id) {
          const options = {
            key: data.key,
            amount: data.amount,
            currency: data.currency,
            name: "Your Service Name",
            description: "Payment Description",
            order_id: data.order_id,
            handler: function (response) {
              alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
              window.location.href = data.redirect_url || '/';
            },
            theme: {
              color: "#F37254",
            },
          };
  
          const rzp = new window.Razorpay(options);
          rzp.open();
        } else {
          alert("Payment details are incomplete.");
        }
      } else {
        alert("Error during payment initiation");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to initiate payment.");
    }
  };
  

//   const handlePayment = async (userId) => {
//     const bookData = {
//       numberOfParticipants: Participants,
//       status: "CONFIRMED",
//     };
//     try {
//       const response = await fetch(
//         `https://eurekatrip-payment-service.azurewebsites.net/paypal/pay/user/${userId}/schedule/${selectedSchedule.scheduleId}`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(bookData),
//         }
//       );
//       const data = await response.json();
//       if (response.ok) {
//         if (data.redirect_url) {
//           window.location.href = data.redirect_url;
//         } else {
//           alert("Redirect URL not found.");
//         }
//       } else {
//         alert("Error during payment initiation");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       alert("Failed to initiate payment.");
//     }
//   };

  const handleAdvancePaymentSubmit = async (e) => {
    e.preventDefault();
    try {
      const guestUserData = {
        email: formData.email,
        fullName: formData.fullName,
        mobileNumber: formData.phoneNumber,
      };
      const response = await fetch(`${BaseAPI}/tour/save/guestUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(guestUserData),
      });
      if (!response.ok) {
        throw new Error("Failed to update tour");
      }
      const responseData = await response.json();
      const userId =
        responseData.data.guestUserId == null
          ? responseData.data.userId
          : responseData.data.guestUserId;
      AdvancePayment(userId);
    } catch (error) {
      console.error("Error updating tour:", error);
    }
  };

  const AdvancePayment = async (userId) => {
    const bookData = {
      numberOfParticipants: Participants,
      status: "CONFIRMED",
    };
    try {
      const response = await fetch(
        `https://eurekatrip-payment-service.azurewebsites.net/paypal/pay/advance/user/${userId}/schedule/${selectedSchedule.scheduleId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bookData),
        }
      );
      const data = await response.json();
      if (response.ok) {
        if (data.redirect_url) {
          window.location.href = data.redirect_url;
        } else {
          alert("Redirect URL not found.");
        }
      } else {
        console.error(data.error);
        alert("Error during payment initiation");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to initiate payment.");
    }
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  useEffect(() => {
    console.log("transactionData", transactionData);

    if (transactionData) {
      fetchTourData(transactionData.data.tourEntity?.tourId);
      setSelectedSchedule(transactionData.data.schedule);
      setParticipants(transactionData.data.totalParticipants);
      setCurrentStep(2);
    }
  }, [transactionData]);
  const renderStep = () => {
    // if (transactionData) {
    //     return (
    //         <>
    //             <CustomerInformation

    //                 formData={formData}
    //                 handleChange={handleChange}
    //                 handleSubmit={handleSubmit}
    //             />
    //             <PaymentInformation
    //                 paymentData={transactionData}
    //                 handleNextStep={handleNextStep}
    //             />
    //         </>
    //     );
    // }
    switch (currentStep) {
      case 1:
        return (
          <CustomerInformation
            selectedSchedule={selectedSchedule}
            formData={formData}
            handleAdvancePaymentSubmit={handleAdvancePaymentSubmit}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            // handleSubmit={handleSubmit}
          />
        );
      case 2:
        return (
          <PaymentInformation
            paymentData={transactionData}
            handleNextStep={handleNextStep}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      {/* {!transactionData &&  <Navbar />} */}
      <div className="fixed-top">
        <div className="payment-navbar">
          <span
            className="eurekatrip-payment-text"
            onClick={() => navigate("/")}
          >
            <img className="eureka-payment-Logo  mb-2" src={eurekaImg}></img>
          </span>
        </div>
      </div>
      <Container fluid className="mt-3">
        <Row className="eureka-navbar-padding">
          <Col xs={12} md={6} className="order-md-1 order-sm-2 order-2">
            {/* <Container> */}
            {renderStep()}
            {/* </Container> */}
          </Col>
          <Col xs={12} md={6} className="order-md-2 order-sm-1 order-1">
            <Card className="mb-5 checkout-container ms-lg-5">
              <Card.Img
                variant="top"
                className="cardImage"
                src={tourImage || "default-image-url.jpg"}
              />
              <Card.Body>
                <Card.Title className="font-medium-title feature-title  text-base ">
                  {tourData?.title}
                </Card.Title>
                <Card.Text>
                  <Row className="mb-2">
                    <Col xs={1}>
                      <Building className="text-bold" />
                    </Col>
                    <Col className=" text-gray-700 dark:text-gray-400 font-feature-tour">
                      From {selectedSchedule?.startDate} To{" "}
                      {selectedSchedule?.endDate}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={1}>
                      <People className="text-bold" />
                    </Col>
                    <Col className=" text-gray-700 dark:text-gray-400 font-feature-tour">
                      {Participants} People
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={1}>
                      <Clock className="text-bold" />
                    </Col>
                    <Col className=" text-gray-700 dark:text-gray-400 font-feature-tour">
                      <span> Duration </span>
                      {selectedSchedule?.duration}{" "}
                      {selectedSchedule?.durationTime}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={1}>
                      <BagX className="text-bold" />
                    </Col>
                    <Col className="text-gray-700 dark:text-gray-400 font-feature-tour">
                      {tourData?.cancellationPolicy?.description}
                    </Col>
                  </Row>
                </Card.Text>
                <Card.Footer className="text-muted">
                  <Row>
                    <Col className="font-medium-title">
                      <strong>Tickets</strong>
                    </Col>
                    <Col className="text-end font-medium">
                      {" "}
                      {Participants} X {selectedSchedule?.currency}-{selectedSchedule?.finalPrice}{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="font-medium-title">
                      <strong>Total</strong>
                    </Col>
                    <Col className="text-end font-medium"> ${totalPrice}</Col>
                  </Row>
                </Card.Footer>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <div className="mt-4">
        {" "}
        <Footer />
      </div>
    </>
  );
};

export default Checkout;
