// src/Components/Login/LoginForm.js
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import facebookIcon from "../../assets/image/facebook.png";
import googleIcon from "../../assets/image/google.png";
import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL } from "../../Constatnts/oAuth.js";
import { useAuth } from "../../context/Authcontext";
import SocialLogin from "../Login/SocialLogin.js";
import axios from 'axios';

function LoginForm({ onLoginSuccess }) {
  const navigate = useNavigate();
  const { user, login, logout } = useAuth();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [LoginFlag, setLoginFlag] = useState(false);

  const BaseAPI = process.env.REACT_APP_PEEK_SERVER_ENDPOINT;

  // useEffect(() => {
  //   const storedUser = localStorage.getItem("user");
  //   if (storedUser) {
  //     console.log("User found in localStorage:", storedUser);
  //     login(JSON.parse(storedUser));
  //   }
  // }, [login]);

  const handleEmailChange = (event) => {
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      username: event.target.value,
    }));
  };

  const handlePasswordChange = (event) => {
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      password: event.target.value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  
  const handleLogin = async () => {
    try {
      const userEmail = credentials.username;
      const response = await fetch(`${BaseAPI}/rest/auth/userEmail/${userEmail}`, { method: "GET" });
      if (!response.ok) {
        throw new Error("Invalid Credential, \n Please try again.");
      }
      const user = await response.json();

      const credentialsJSON = JSON.stringify(credentials);

      const loginResponse = await fetch(`${BaseAPI}/rest/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: credentialsJSON,
      });

      if (!loginResponse.ok) {
        throw new Error("Incorrect username or password");
      }

      const data = await loginResponse.json();
      toast.success("Login successful");
      localStorage.setItem("user", JSON.stringify(data.data));
      login(data.data);
      onLoginSuccess();
      navigate('/');
      
    } catch (error) {
      console.error("Login failed:", error);
      toast.error(error.message);
    }
  };

  const handleCancel = () => {
    console.log("Cancel button clicked");
    window.location.href = '/';
  };
  
  const handleSocialLoginSuccess = (token, provider) => {
    console.log("provider==>",provider)
    const endpoint = provider === 'google' ? '/auth/google' : '/auth/facebook';
    console.log(BaseAPI+endpoint)
    const eurekatripSocialLoginEndPoint = BaseAPI+endpoint;

    
    axios.post(eurekatripSocialLoginEndPoint, { token })  
      .then(response => {
        console.log(response.data);
       localStorage.setItem("user", JSON.stringify(response.data));
       login(response.data.data);
       onLoginSuccess();

       if(response.data){
        setLoginFlag(true)
       }
       navigate('/');
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };

  return (
    <>
      <section className="vh-100 mt-5">
        <div className="container py-4 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="card shadow-2-strong">
                <div className="card-body mt-2 p-4 text-center">
                  <div className="d-flex justify-content-between">
                    <h4 className="mb-2 ms-3 text-lg-center">Log in or sign up</h4>
                    <Button className="btn btn-danger mb-3" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </div>
                  <hr className="mt-1 mb-3" />
                  {/* <div>
                    <p className="mb-1">
                      <span style={{ color: "green", marginRight: "6px" }}>✓</span> Access and manage your tickets
                    </p>
                    <p className="mb-5 mt-1 ms-2">
                      <span style={{ color: "green", marginRight: "5px" }}>✓</span> Earn credits and activity discounts
                    </p>
                  </div> */}

                  <div className="form-outline mb-4">
                    {/* <button className="btn mb-3 me-1 login" type="button" style={{ border: "1px solid #ccc" }}>
                      <img src={facebookIcon} alt="Facebook" style={{ width: "24px", height: "24px", marginRight: "8px" }} />
                      Continue with Facebook
                    </button>

                    <a className="btn mb-3 login" href={GOOGLE_AUTH_URL} style={{ width: "52%", border: "1px solid #ccc" }}>
                      <img src={googleIcon} alt="Google" style={{ width: "24px", height: "24px", marginRight: "8px" }} />
                      Continue with Google
                    </a> */}
                   {/* <div><SocialLogin onSocialLoginSuccess={handleSocialLoginSuccess} /></div> */}
                  </div>
                  <div className="d-flex align-items-center my-3">
                    <hr className="flex-grow-1" />
                    <span className="mx-2">OR</span>
                    <hr className="flex-grow-1" />
                  </div>

                  <div>
                    <div className="mb-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={credentials.username}
                        onChange={handleEmailChange}
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={credentials.password}
                        onChange={handlePasswordChange}
                        onKeyDown={handleKeyDown}
                      />
                    </div>

                    <div className="d-grid">
                      <Button className="btn btn-primary" onClick={handleLogin}>
                        Continue
                      </Button>

                      <ToastContainer />
                    </div>

                    <Button onClick={() => navigate("/sign-up")} variant="link">
                      Register as a new user
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LoginForm;
