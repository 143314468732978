import React, { useState } from "react";
import Navbar from "../common/Navbar";
import Adventure from "./Adventure";
import Recomandation from "../common/Recomandation";
import Footer from "../../footer/footer";
import Discover from "../common/Discover";
import { Col, Row } from "react-bootstrap";
import adventureImg from "../../../assets/image/adventureImg.png";

function AdventureHome() {
  const [isAdventurePage, setIsAdventurePage] = useState(true); 
  return (
    <>
   
      <Adventure />
      <Discover />
      <Recomandation />
      <Footer />
    </>
  );
}

export default AdventureHome;
