import React from "react";
import Navbar from "../common/Navbar";
import Recomandation from "../common/Recomandation";
import Footer from "../../footer/footer";
import Discover from "../common/Discover";
import { Col, Row } from "react-bootstrap";
import experienceImg from "../../../assets/image/exprienceHome.png";
import Experience from "../experience/Experience";

function ExperienceHome() {
  return (
    <>
      <Navbar />
      <div>
        <Row>
          <Col xs={12} className="position-relative text-white w-100 my-4 ">
            <div>
              {/* <img
                src={experienceImg}
                alt=""
                className="experience-theme-image w-100 mt-5"
                style={{ objectFit: "cover" }}
              /> */}
            </div>
          </Col>
        </Row>
      </div>
      <Experience />
      <Discover />
      <Recomandation />
      <Footer />
    </>
  );
}

export default ExperienceHome;
