import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Discover from "../common/Discover";
import Recomandation from "../common/Recomandation";
import Footer from "../../footer/footer";
import "../../../assets/css/tourpage.css";
import { useNavigate } from "react-router-dom";

const SuggestTour = ({ suggestedTourData }) => {
  console.log("suggestedTourData===>", suggestedTourData);

  useEffect(() => {
    window.scrollTo(0, 0);

  })

  const navigate = useNavigate();
  const handleTourCardClick = (tour) => {
    const tourId = tour.tourId;
    console.log("tourId", tourId)
    navigate("/scheduletour", {
      state: {
        tourId,
        // pageName: pageName,
        // selectedCategory: selectedCategory,
        // selectedSubCategory: selectedSubCategory,
        // selectedThemeName: selectedThemeName
      }
    });
  };
  return (
    <>
    <div  className=" ms-2 schedule-main-title"  style={{marginTop:'100px'}}>
             Suggested Tours
              </div>
      <div className="tour-container " style={{marginTop:'70px'}}>
      
        {suggestedTourData && suggestedTourData.length > 0 ? (
          <Row xs={1} md={2} lg={4} className="g-4 ex-container">
            {suggestedTourData.map((tour) => (
              <Col key={tour.tourId}>
                <Card className="mb-5 ms-5 tour-card">
                  <Card.Img
                    variant="top"
                    onClick={() => handleTourCardClick(tour)}
                    src={tour.tourAssets[0]?.assetUrl}
                    className="tour-card-img"
                  />
                  <Card.Body className="tour-card-body">
                    <Card.Title className="tour-card-title ">
                      {tour.title}
                    </Card.Title>
                    <Card.Text className="tour-card-text tour-description">
                      {tour.description}
                    </Card.Text>
                    <Card.Text className="tour-card-price">
                      ${tour.price}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <div className="mt-3 upcoming-tours">Tours will be coming soon...</div>
        )}
      </div>
      <Discover />
      <Recomandation />
      <Footer />
    </>
  );
};

export default SuggestTour;
