import { Form, Button, Modal, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../context/Authcontext";
import SocialLogin from "../Login/SocialLogin.js";
import otpImg from "../../assets/image/Twitter_Verified_Badge.svg.png";
import axios from "axios";
import SetPasswordForm from "../Login/SetPasswordForm.js";
import PhoneInput from "react-phone-input-2";

const LoginPopup = ({
  onLoginSuccess,
  showLoginPopup,
  handleLoginClose,
  showRegisterForm,
  setShowRegisterForm,
  setHandleLoginPopup,
  showforgotpassword,
  setshowforgotpassword,
}) => {
  const [showOtpVerification, setShowOtpVerification] = useState(false);
  const [showSetPasswordScreen, setShowSetPasswordScreen] = useState(false);
  const [LoginPopupAfterRegister, setShowLoginPopupAfterRegister] =
    useState(false);

  const [registerResponseData, setregisterResponseData] = useState({});
  const [forgotEmail, setForgotEmail] = useState({});
  const [showLoginFormAfterPasswordSet, setShowLoginFormAfterPasswordSet] =
    useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const { user, login } = useAuth();

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const location = useLocation();

  const BaseAPI = process.env.REACT_APP_PEEK_SERVER_ENDPOINT;

 

  const handleEmailChange = (event) => {
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      username: event.target.value,
    }));
  };

  const handlePasswordChange = (event) => {
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      password: event.target.value,
    }));
  };

 

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    try {
      const userEmail = credentials.username;
      const response = await fetch(
        ` ${BaseAPI}/rest/auth/userEmail/${userEmail}`,
        { method: "GET" }
      );
      if (!response.ok) {
        throw new Error("Invalid Credential, \n Please try again.");
      }
      const user = await response.json();

      const credentialsJSON = JSON.stringify(credentials);

      const loginResponse = await fetch(`${BaseAPI}/rest/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: credentialsJSON,
      });

      if (!loginResponse.ok) {
        throw new Error("Incorrect username or password");
      }

      const data = await loginResponse.json();

      toast.success("Login successful");

      handleLoginClose();

      localStorage.setItem("user", JSON.stringify(data.data));
      login(data.data);
      onLoginSuccess();
      setShowRegisterForm(false);
      const currentLocation = window.location.pathname;

      navigate({ state: { redirectPath: currentLocation } });

      window.location.reload();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handlePasswordSetSuccess = () => {
    handleLoginClose();

    setShowLoginPopupAfterRegister(true);
  };

  const handleSocialLoginSuccess = (token, provider) => {
    console.log("provider==>", provider);
    const endpoint = provider === "google" ? "/auth/google" : "/auth/facebook";
    console.log(BaseAPI + endpoint);
    const eurekatripSocialLoginEndPoint = BaseAPI + endpoint;

    axios
      .post(eurekatripSocialLoginEndPoint, { token })
      .then((response) => {
        console.log(response.data);
        localStorage.setItem("user", JSON.stringify(response.data));
        login(response.data.data);
        onLoginSuccess();
        handleLoginClose();
        navigate("/");
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleRegisterPopup = () => {
    setShowRegisterForm(true);
  };
  const handleforgotPopup = () => {
    setshowforgotpassword(true);
  };

  const backtologin = () => {
    console.log("login");
    setShowRegisterForm(false);
    setshowforgotpassword(false);
    setHandleLoginPopup(true);
  };
  const handleRegisterClose = () => {
    setShowRegisterForm(false);
    setHandleLoginPopup(true);
  };

  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobileNumber: "",
    roleName: "USER",
    countryCode: "",
    agreedTerms: false,
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    email: "",
    mobileNumber: "",
    confirmPassword: "",
    confirmPasswordRequired: "",
    agreedTerms: "",
    password: "",
  });

  const validateName = (firstName) => {
    if (!firstName?.trim()) {
      setFormErrors((prev) => ({
        ...prev,
        firstName: "First name is required.",
      }));
      return false;
    }
    setFormErrors((prev) => ({ ...prev, firstName: "" }));
    return true;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setFormErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address.",
      }));
      return false;
    }
    setFormErrors((prev) => ({ ...prev, email: "" }));
    return true;
  };

  const validateMobileNumber = (mobileNumber) => {
    const phoneRegex = /^\d+$/;
    if (!phoneRegex.test(mobileNumber)) {
      setFormErrors((prev) => ({
        ...prev,
        mobileNumber: "Please enter a valid mobile number.",
      }));
      return false;
    }
    setFormErrors((prev) => ({ ...prev, mobileNumber: "" }));
    return true;
  };

  const validateConfirmPassword = (confirmPassword) => {
    if (!confirmPassword) {
      setFormErrors((prev) => ({
        ...prev,
        confirmPasswordRequired: "Confirm password is required.",
        confirmPassword: "",
      }));
      return false;
    } else {
      setFormErrors((prev) => ({ ...prev, confirmPasswordRequired: "" }));
    }
    if (confirmPassword) {
      if (formData.password !== confirmPassword) {
        setFormErrors((prev) => ({
          ...prev,
          confirmPassword: "Passwords do not match.",
        }));
        return false;
      } else {
        setFormErrors((prev) => ({ ...prev, confirmPassword: "" }));
      }
    }
    return true;
  };

  const validatePassword = (password) => {
    if (password?.length < 8) {
      setFormErrors((prev) => ({
        ...prev,
        password: "Password must be at least 8 characters long.",
      }));
      return false;
    }
    setFormErrors((prev) => ({ ...prev, password: "" }));
    return true;
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "agreedTerms" ? checked : value,
    }));

    if (name === "email") {
      validateEmail(value);
    } else if (name === "mobileNumber") {
      const extractedMobileNumber = value.slice(-10);
      setFormData((prev) => ({
        ...prev,
        mobileNumber: extractedMobileNumber,
      }));
      validateMobileNumber(extractedMobileNumber);
    } else if (name === "firstName") {
      validateName(value);
    } else if (name === "password") {
      validatePassword(value);
    } else if (name === "confirmPassword") {
      validateConfirmPassword(value);
    }
  };


  // Updated PhoneInput handler
const handlePhoneInputChange = (phone, country) => {
  const mobileNumber = phone.slice(-10);
  setFormData({
    ...formData,
    mobileNumber: mobileNumber,
    countryCode: country.dialCode,
  });
  validateMobileNumber(mobileNumber);
};

  const handleRegister = async (e) => {
    
    e.preventDefault();
    const isPasswordValid = validatePassword(formData.password);
    const isConfirmPasswordValid = validateConfirmPassword(
      formData.confirmPassword
    );
    const isNameValid = validateName(formData.firstName);
    const isEmailValid = validateEmail(formData.email);
    const isMobileNumberValid = validateMobileNumber(formData.mobileNumber);

    

    if (!isEmailValid || !isMobileNumberValid || !isNameValid) {
      console.error("Validation errors:", formErrors);
      return;
    }

    const registerData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      countryCode: formData.countryCode,
      mobileNumber: parseInt(formData.mobileNumber, 10),
      email: formData.email,
      roleName: "USER",
    };

    try {
      const response = await fetch(`${BaseAPI}/rest/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(registerData),
      });

      const data = await response.json();
      console.log("Response:", response);
      console.log("response.data", response.data);

      setregisterResponseData(data.data);
      if (data.statusCode === 409) {
        alert("Email is Already Exist");
        toast.error("Email is Already Exist");
      }
      if (data.statusCode === 200) {
        setTimeout(() => {
          setHandleLoginPopup(true);
          setShowOtpVerification(true);
        }, 1000);
      }

      if (!response.ok) {
        throw new Error(
          data.message || "Something went wrong with the registration."
        );
      }

      console.log("Registration successful:", data);
    } catch (error) {
      console.log("error message", error.message);

      if (error.message === "Email is Already Exist") {
        toast.error("Email is Already Exist");
      }

      console.error("Registration failed:", error.message);
      toast.error(
        error.message || "Something went wrong with the registration."
      );

      setError(error.message || "Something went wrong with the registration.");
    }
  };

  //Otp verification

  const [otp, setOtp] = useState(Array(6).fill(""));

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.value && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  useEffect(() => {
    otp.forEach((data, index) => {
      if (data !== "") {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput && document.activeElement !== nextInput) {
          nextInput.focus();
        }
      }
    });
  }, [otp]);

  const otpVerification = async () => {
    const otpData = {
      email: formData.email,
      inputOTP: otp.join(""),
    };

    try {
      const response = await fetch(`${BaseAPI}/rest/auth/verify-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(otpData),
      });

      const data = await response.json();
      console.log("otp data====>", data);

      if (!response.ok) {
        throw new Error(
          data.message || "Something went wrong with the OTP verification."
        );
      }

      if (data.statusCode === 200) {
        setShowSetPasswordScreen(true);
        toast.success("OTP verified Successfully", { autoClose: 3000 });
      } else if (data.statusCode === 401 && data.message === "Invalid OTP") {
        setErrorMessage("Invalid OTP");
      } else {
        setErrorMessage("OTP verification failed. Please try again later.");
      }

      console.log("OTP verification successful:", data);
    } catch (error) {
      console.log("Error during OTP verification:", error.message);
      setErrorMessage("Failed to verify OTP. Please try again later.");
    }
  };

  const OtpVerificationForm = () => (
    <div className="otp-verification-form">
      <div className="otp-header">
        <div className="otp-icon">
          <img src={otpImg} alt="verification icon" />
        </div>
        <h4>Enter OTP Code</h4>
        <div className="font-medium">
          <span>
            OTP sent on <span className="text-primary">{formData.email}</span>,
            please check
          </span>
        </div>
      </div>
      <div className="otp-inputs">
        {otp.map((data, index) => (
          <input
            key={index}
            id={`otp-input-${index}`}
            className="otp-input rounded"
            type="text"
            name="otp"
            maxLength="1"
            value={data}
            onChange={(e) => handleOtpChange(e.target, index)}
            onFocus={(e) => e.target.select()}
          />
        ))}
      </div>

      <Row className="verification-btn">
        <div className="col-6  mb-2 ms-5">
          <button
            className="btn btn-primary"
            // onClick={() => {
            //     console.log('OTP Verified:', otp.join(''));
            //     setShowSetPasswordScreen(true);
            // }}
            onClick={otpVerification}
          >
            Verify OTP
          </button>
          {errorMessage && <div className="text-danger">{errorMessage}</div>}
        </div>

        <div className="col-6 resend-otp ms-5">
          {/* <button
            className="btn btn-primary w-100 resend-otp-btn"
            // onClick={() => {
            //     console.log('OTP Verified:', otp.join(''));
            //     setShowSetPasswordScreen(true);
            // }}
            onClick={handleRegister}
          >
            Resend OTP
          </button> */}

          <a className=" w-100 resend-otp-btn" href="" onClick={handleRegister}>
            Send New OTP
          </a>
        </div>
      </Row>
    </div>
  );

  //password verification code

  const handleforgotEmailChange = (event) => {
    setForgotEmail(event.target.value);
  };

  const forgotPassword = async (e) => {
    e.preventDefault();
    const Data = {
      email: forgotEmail,
    };

    try {
      const response = await fetch(`${BaseAPI}/rest/auth/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Data),
      });

      const data = await response.json();
      console.log("Response:", response);

      if (data.statusCode === 200) {
        toast.success("OTP Sent on email ", { autoClose: 3000 });
        setTimeout(() => {
          // handleRegisterClose();
          setHandleLoginPopup(true);
          setShowOtpVerification(true);
        }, 1000);
      }

      console.log("Registration successful:", data);
    } catch (error) {
      // console.log("error message", error.message);
    }
  };

  const RegisterAfterloginClose = () => {
    setShowLoginPopupAfterRegister(false);
  };
  return (
    <>
      <Modal show={showLoginPopup} onHide={handleLoginClose}>
        <Modal.Header closeButton>
          {showRegisterForm && <Modal.Title>Registration</Modal.Title>}
          {!showRegisterForm && <Modal.Title>Login</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          {/* <SetPasswordForm email={formData.email} /> */}
          {showLoginFormAfterPasswordSet ? (
            <div className="h-100">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="card shadow-2-strong">
                  <div className="card-body mt-2 p-4 text-center">
                    <div className="form-outline mb-4">
                      <div>
                        <SocialLogin
                          onSocialLoginSuccess={handleSocialLoginSuccess}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center my-3">
                      <hr className="flex-grow-1" />
                      <span className="mx-2">OR</span>
                      <hr className="flex-grow-1" />
                    </div>

                    <div>
                      <div className="mb-3">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={credentials.username}
                          onChange={handleEmailChange}
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          value={credentials.password}
                          onChange={handlePasswordChange}
                          onKeyDown={handleKeyDown}
                        />
                      </div>

                      <div className="d-grid">
                        <Button
                          className="btn btn-primary"
                          onClick={handleLogin}
                        >
                          Continue
                        </Button>

                        <ToastContainer />
                      </div>
                      <Row>
                        <div className="col-6">
                          <Button onClick={handleforgotPopup} variant="link">
                            Forgot password
                          </Button>
                        </div>
                        <div className="col-6">
                          <Button onClick={handleRegisterPopup} variant="link">
                            Register as a new user
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : showSetPasswordScreen ? (
            <SetPasswordForm
              email={formData.email}
              forgotEmail={forgotEmail}
              onPasswordSetSuccess={handlePasswordSetSuccess}
            />
          ) : showOtpVerification ? (
            <OtpVerificationForm />
          ) : showforgotpassword ? (
            <div>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="tour-form-label">
                    Enter your email :
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="forgotEmail"
                    onChange={handleforgotEmailChange}
                    style={{ width: "100" }}
                  />
                  {formErrors.email && (
                    <div style={{ color: "red", fontSize: "small" }}>
                      {formErrors.email}
                    </div>
                  )}
                </Form.Group>
                <Button className="btn btn-primary" onClick={forgotPassword}>
                  submit
                </Button>
              </Col>
              <Button onClick={backtologin} variant="link">
                Back to Login
              </Button>
            </div>
          ) : !showRegisterForm ? (
            <div className="h-100">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="card shadow-2-strong">
                  <div className="card-body mt-2 p-4 text-center">
                    <div className="form-outline mb-4">
                      <div>
                        <SocialLogin
                          onSocialLoginSuccess={handleSocialLoginSuccess}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center my-3">
                      <hr className="flex-grow-1" />
                      <span className="mx-2">OR</span>
                      <hr className="flex-grow-1" />
                    </div>

                    <div>
                      <div className="mb-3">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={credentials.username}
                          onChange={handleEmailChange}
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          value={credentials.password}
                          onChange={handlePasswordChange}
                          onKeyDown={handleKeyDown}
                        />
                      </div>

                      <div className="d-grid">
                        <Button
                          className="btn btn-primary"
                          onClick={handleLogin}
                        >
                          Continue
                        </Button>

                        <ToastContainer />
                      </div>
                      <Row>
                        <div className="col-6">
                          <Button onClick={handleforgotPopup} variant="link">
                            Forgot password
                          </Button>
                        </div>
                        <div className="col-6">
                          <Button onClick={handleRegisterPopup} variant="link">
                            Register as a new user
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Form className="p-3">
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="tour-form-label">
                      First Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    />
                    {formErrors.firstName && (
                      <div style={{ color: "red", fontSize: "small" }}>
                        {formErrors.firstName}
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label className="tour-form-label">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      className="w-100"
                      type="text"
                      value={formData.lastName}
                      onChange={handleChange}
                      name="lastName"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="tour-form-label">Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      onChange={handleChange}
                      style={{ width: "100" }}
                    />
                    {formErrors.email && (
                      <div style={{ color: "red", fontSize: "small" }}>
                        {formErrors.email}
                      </div>
                    )}
                  </Form.Group>
                </Col>

               

                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="tour-form-label">
                      Mobile Number <span className="text-danger">*</span>
                    </Form.Label>
                    <div
                      className="d-flex align-items-center"
                      style={{ width: "210px" }}
                    >
                       <PhoneInput
                      country={"in"}
                      value={formData.mobileNumber}
                      onChange={handlePhoneInputChange} 
                      inputProps={{
                        name: "mobileNumber",
                        required: true,
                        autoFocus: true,
                      }}
                      inputStyle={{ width: "100%" }}
                      inputClass="form-control mobile-input"
                    />
                    </div>
                    {formErrors.mobileNumber && (
                      <div style={{ color: "red", fontSize: "small" }}>
                        {formErrors.mobileNumber}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              

              <Col
                sm={12}
                className="mt-2"
                style={{ textAlign: "end", border: "2px" }}
              >
                <Button
                  variant="primary mt-2"
                  type="submit"
                  onClick={handleRegister}
                >
                  Continue
                </Button>
              </Col>
            </Form>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={LoginPopupAfterRegister} onHide={RegisterAfterloginClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="card shadow-2-strong">
                <div className="card-body mt-2 p-4 text-center">
                
                  <div className="d-flex align-items-center my-3">
                    <hr className="flex-grow-1" />
                    <span className="mx-2">OR</span>
                    <hr className="flex-grow-1" />
                  </div>
                  <div>
                    <div className="mb-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={credentials.username}
                        onChange={handleEmailChange}
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={credentials.password}
                        onChange={handlePasswordChange}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    <div className="d-grid">
                      <Button className="btn btn-primary" onClick={handleLogin}>
                        Continue
                      </Button>
                      <ToastContainer />
                    </div>
                    <Row>
                      <div className="col-6">
                        <Button onClick={handleforgotPopup} variant="link">
                          Forgot password
                        </Button>
                      </div>
                      
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default LoginPopup;
