import React from 'react'
import Navbar from '../common/Navbar'
import Adventure from '../adventure/Adventure'
import Recomandation from '../common/Recomandation'
import Footer from '../../footer/footer'
import Discover from '../common/Discover'
import { Col, Row } from 'react-bootstrap'
import transformationHome from '../../../assets/image/transformationHome.png';
import spiritualHome from '../../../assets/image/SpiritualHome.png'
import Spiritual from '../spiritual/Spiritual'


const SpiritualHome = () => {
    return (
        <>
            <Navbar />
            <div >
                <Row >
                    <Col xs={12} className="position-relative text-white w-100 my-4">
                        {/* <img
                            src={spiritualHome}
                            alt=""
                            className="experience-theme-image w-100 mt-5"
                            style={{ objectFit: "cover" }}
                        /> */}

                    </Col>
                </Row>
            </div>
            <Spiritual />
            <Discover />
            <Recomandation />
            <Footer />
        </>
    )
}

export default SpiritualHome
