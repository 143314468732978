import React, { createContext, useContext, useState, useEffect } from 'react';

const TourContext = createContext();

export const useTour = () => useContext(TourContext);

export const TourProvider = ({ children }) => {
    const [selectedCategory, setSelectedCategory] = useState(localStorage.getItem('selectedCategory'));
    const [selectedSubCategory, setSelectedSubCategory] = useState(localStorage.getItem('selectedSubCategory'));

    useEffect(() => {
        localStorage.setItem('selectedCategory', selectedCategory);
        localStorage.setItem('selectedSubCategory', selectedSubCategory);
    }, [selectedCategory, selectedSubCategory]);

    const value = {
        selectedCategory,
        setSelectedCategory,
        selectedSubCategory,
        setSelectedSubCategory
    };

    return <TourContext.Provider value={value}>{children}</TourContext.Provider>;
};
