import React from "react";
import NavbarHome from "../pages/common/Navbar";
import Footer from "./footer";
import Navbar from '../pages/common/Navbar';

function FaQ() {
  return (
    <>
       <Navbar/>
      <div className="container pt-4">
        <div className="mt-5">
            <h2 className="contactus-title">FAQ</h2>
          <h4 className="contactus-title">What is '360 Explorer' and 'EurekaTrip'?</h4>
          <p className="faq-medium-title">
            360 Explorer Inc. is a seasoned adventure company with over 10 years
            of expertise in executing top-notch global adventure activities. We
            excel in creating SOPs, ensuring safety, and training guides and
            porters. EurekaTrip.com is the marketplace platform of 360 Explorer
            Inc. Unlike other tech platforms, we don't just book trips; we
            ensure your experience is safe and extraordinary by working only
            with handpicked and trained partners. 360 Explorer Inc. oversees
            their performance to guarantee an exceptional adventure for you.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title">What types of tours and expeditions does EurekaTrip offer?</h4>
          <p className="faq-medium-title">
            - We offer a variety of adventure, life transformational,
            experiential tours, treks, and expeditions worldwide.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title">How do I book a tour on EurekaTrip.com?</h4>
          <p className="faq-medium-title">
            Simply browse our available tours, select your preferred adventure,
            and follow the booking prompts on our platform.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title">What is the "360 Explorer Miles" Program?</h4>
          <p className="faq-medium-title" >
            The program allows you to earn miles with each booking, which can be
            redeemed for discounts on future trips.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> How can I use my 360 Explorer Miles?</h4>
          <p className="faq-medium-title">
            You can apply your miles at checkout to reduce the cost of your next
            adventure.{" "}
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title">What payment methods are accepted on EurekaTrip.com?</h4>
          <p className="faq-medium-title">We accept major credit cards, debit cards, and PayPal.</p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> Is there a discount for booking multiple trips</h4>
          <p className="faq-medium-title">
            Yes, you can get 5% to 10% off by booking multiple trips at once.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> Can I customize my adventure itinerary?</h4>
          <p className="faq-medium-title">
            Yes, we offer personalized itineraries to meet your specific needs
            and preferences.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> What safety measures are in place for expeditions?</h4>
          <p className="faq-medium-title">
            We prioritize safety with trained guides, proper equipment, and
            thorough risk assessments.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> What is included in the trip cost?</h4>
          <p className="faq-medium-title">
            Trip costs typically include accommodation, meals, guided tours, and
            some equipment. Specific inclusions are detailed in each trip
            listing.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> Are there age restrictions for the tours?</h4>
          <p className="faq-medium-title">
            Age restrictions vary by tour and are listed in the trip details.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> How experienced do I need to be for a trek or expedition?</h4>
          <p className="faq-medium-title">
            We offer trips for all experience levels, from beginners to seasoned
            adventurers.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> What should I pack for my adventure?</h4>
          <p className="faq-medium-title" >
            A packing list is provided for each tour, detailing necessary
            clothing, gear, and personal items.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> Can I cancel or reschedule my booking?</h4>
          <p className="faq-medium-title">
            Yes, our cancellation and rescheduling policies are detailed in the
            terms and conditions of each booking.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> Do you offer group discounts?</h4>
          <p className="faq-medium-title">
            Yes, discounts are available for group bookings. Contact us for more
            details.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> How do I earn a free adventure trip?</h4>
          <p className="faq-medium-title">
            Pay for 10 members and gather a total of 11 members to receive a
            free trip for yourself.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> Are your tours environmentally friendly?</h4>
          <p className="faq-medium-title">
            Yes, we prioritize sustainable practices and support eco-friendly
            initiatives.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> Can I gift an adventure trip to someone?</h4>
          <p>
            {" "}
            Yes, gift vouchers are available for all our tours and expeditions.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> What languages are the tours conducted in?</h4>
          <p className="faq-medium-title">
            Our tours are primarily in English, but guides for other languages
            may be available upon request.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> Do you provide travel insurance?</h4>
          <p className="faq-medium-title">
            {" "}
            Travel insurance is not included but is highly recommended. We can
            provide recommendations of our partner insurance.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title" > What if I have dietary restrictions?</h4>
          <p className="faq-medium-title">
            {" "}
            We accommodate dietary restrictions with advance notice. Please
            specify any requirements when booking.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> How do I contact customer support?</h4>
          <p className="faq-medium-title">
            {" "}
            You can reach us via the contact form on our website, email, or
            phone.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> Are there any hidden fees?</h4>
          <p className="faq-medium-title">
            {" "}
            No, all costs and fees are clearly outlined in the trip details and
            booking process.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> What is the duration of the tours?</h4>
          <p className="faq-medium-title">
            {" "}
            Tour durations vary and are specified in each trip description.
          </p>
        </div>

        <div className="mb-2 mt-3">
          <h4 className="contactus-title"> How do I stay updated on new tours and promotions?</h4>
          <p className="faq-medium-title">
            {" "}
            Subscribe to our newsletter and follow us on social media to receive
            the latest updates and exclusive offers.
          </p>
        </div>
      </div>

      <Footer/>
    </>
  );
}

export default FaQ;
