import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import NavbarHome from "../../Components/pages/common/Navbar";
import Footer from "../footer/footer";
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  const BaseAPI = process.env.REACT_APP_PEEK_SERVER_ENDPOINT;
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobileNumber: "",
    roleName: 'USER',
    agreedTerms: false,
  });

  const [formErrors, setFormErrors] = useState({
    name:"",
    email: "",
    mobileNumber: "",
    confirmPassword: "",
    agreedTerms: "",
  });

  const validateName = (name) => {
    if (!name.trim()) {
      setFormErrors((prev) => ({
        ...prev,
        name: "Name is required.",
      }));
      return false;
    }
    setFormErrors((prev) => ({ ...prev, name: "" }));
    return true;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setFormErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address.",
      }));
      return false;
    }
    setFormErrors((prev) => ({ ...prev, email: "" }));
    return true;
  };

  const validateMobileNumber = (mobileNumber) => {
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(mobileNumber)) {
      setFormErrors((prev) => ({
        ...prev,
        mobileNumber: "Please enter a valid 10-digit mobile number.",
      }));
      return false;
    }
    setFormErrors((prev) => ({ ...prev, mobileNumber: "" }));
    return true;
  };

  const validateConfirmPassword = () => {
    if (formData.password && formData.confirmPassword && formData.password !== formData.confirmPassword) {
      setFormErrors((prev) => ({
        ...prev,
        confirmPassword: "Passwords do not match.",
      }));
      return false;
    }
    setFormErrors((prev) => ({ ...prev, confirmPassword: "" }));
    return true;
  };


  const handleChange = (e) => {

    const { name, value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "agreedTerms" ? checked : value,
    }));

    if (name === "email") {
      validateEmail(value);
    } else if (name === "mobileNumber") {
      validateMobileNumber(value);
    }
    else if (name === "firstName") {
      validateName(value);
    }


    if (name === "password" || name === "confirmPassword") {
      validateConfirmPassword();
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    console.log("Form data submitted:", formData);

    const isNameValid = validateName(formData.name);
    const isEmailValid = validateEmail(formData.email);
    const isMobileNumberValid = validateMobileNumber(formData.mobileNumber);
    const isConfirmPasswordValid = validateConfirmPassword();

    if (!formData.agreedTerms) {
      setFormErrors((prev) => ({
        ...prev,
        agreedTerms: "Must accept terms and conditions before submitting!",
      }));
      return;
    } else {
      setFormErrors((prev) => ({ ...prev, agreedTerms: "" }));
    }

    if (!isEmailValid || !isMobileNumberValid || !isNameValid || !isConfirmPasswordValid) {
      console.error("Validation errors:", formErrors);
      return;
    }

    const payload = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
      mobileNumber: parseInt(formData.mobileNumber, 10),
      roleName: formData.roleName,
    };

    try {
      const response = await fetch(`${BaseAPI}/rest/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      console.log("Response:", response);
      if (!response.ok) {
        throw new Error(
          data.message || "Something went wrong with the registration."
        );
      }

      console.log("Registration successful:", data);
      toast.success("Registration successful");

      navigate("/");
    } catch (error) {
      console.error("Registration failed:", error.message);
      toast.error(
        error.message || "Something went wrong with the registration."
      );

      setError(error.message || "Something went wrong with the registration.");
    }
  };

  return (
    <>
      <div>
        <NavbarHome />
      </div>

      <div className="mt-5">
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <Form className="p-4 rounded-3 bg-white">
              <h4 className="font-bold mt-5">Complete Sign up</h4>
              <hr style={{ border: "1px solid #ccc", width: "85%" }} />

              <Form.Group className="mb-3">
                <Form.Label className="text-secondary fw-semibold">
                  Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleChange}
                  style={{ width: "85%" }}
                />
                {formErrors.name && (
                  <div style={{ color: "red", fontSize: "small" }}>
                    {formErrors.name}
                  </div>
                )}
                
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="text-secondary fw-semibold">
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  onChange={handleChange}
                  style={{ width: "85%" }}
                />
                {formErrors.email && (
                  <div style={{ color: "red", fontSize: "small" }}>
                    {formErrors.email}
                  </div>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="text-secondary fw-semibold">
                  Mobile Number
                </Form.Label>
                <Form.Control
                  type="tel"
                  name="mobileNumber"
                  onChange={handleChange}
                  style={{ width: "85%" }}
                />
                {formErrors.mobileNumber && (
                  <div style={{ color: "red", fontSize: "small" }}>
                    {formErrors.mobileNumber}
                  </div>
                )}
                <span style={{ fontSize: "smaller" }}>
                  We will use this phone number for your tickets.
                </span>
              </Form.Group>

              <Row className="gx-2">
                <Col sm={6} className="pe-1">
                  <Form.Group className="mb-3">
                    <Form.Label className="text-secondary fw-semibold">
                      Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      onChange={handleChange}
                      style={{ width: "70%" }}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6} className="ps-1">
                  <Form.Group className="mb-3 ">
                    <Form.Label className="text-secondary fw-semibold">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      onChange={handleChange}
                      style={{ width: "70%" }}
                    />
                    {formErrors.confirmPassword && formData.password && formData.confirmPassword && (
                      <div style={{ color: "red", fontSize: "small" }}>
                        {formErrors.confirmPassword}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="I agree to the terms and conditions"
                  name="agreedTerms"
                  checked={formData.agreedTerms}
                  onChange={handleChange}
                  required
                />
                {formErrors.agreedTerms && (
                  <div style={{ color: "red", fontSize: "small" }}>
                    {formErrors.agreedTerms}
                  </div>
                )}
              </Form.Group>

              <Button
                variant=""
                type="submit"
                className="rounded-2 register mb-4"
                onClick={handleRegister}
              >
                Register
              </Button>
              <ToastContainer/>
            </Form>
          </Col>
        </Row>
      </div>

      <div>
        <Footer />
      </div>
    </>
  );
};

export default Register;
