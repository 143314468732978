import homepageImg from "../../assets/image/homepage.png";
import { Col, Row } from "react-bootstrap";
import Footer from "../footer/footer.js";
import Adventure from "../pages/adventure/Adventure.js";
import NavbarHome from "../pages/common/Navbar.js";
import TrendingHome from "../pages/common/TrendingHome.js";
import React, { useState, useEffect, navigate } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "../../assets/css/header.css";
import Transformation from "../pages/transformation/Transformation.js";
import Discover from "../pages/common/Discover.js";
import Experience from "../pages/experience/Experience.js";
import Spiritual from "../pages/spiritual/Spiritual.js";
import Recomandation from "../pages/common/Recomandation.js";
import Themes from "./Themes.js";
import FeatureTour from "./FeatureTour.js";
import Section from "./Section.js";
import SpecialTours from "./SpecialTours.js";
import SpecialTourBmm from"./SpecialTourBmm.js";
import ExpeditionInfo from "../pages/common/ExpeditionInfo .js";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <NavbarHome />
      </div>
      <Section />
      <SpecialTours/>
      <SpecialTourBmm/>
      <Themes />
      <Discover />
      <FeatureTour />
      <ExpeditionInfo />


      <Recomandation />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Header;
