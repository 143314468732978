import React from "react";
import Navbar from '../pages/common/Navbar';
import Footer from "./footer";
import { Button } from "react-bootstrap";
const Privacy = () => {
    return (
        <>
        <Navbar/>
        <div className="container mt-5 pt-5">
            {/* <Button variant="danger" style={{marginLeft:"1200px"}}>Delete</Button> */}
            <h1>Privacy Policy</h1>
            <p>
                We at 360 Explorer Inc. ("360 Explorer LLP", "we," "us," "our") understand that our users ("you," "your")
                care about how your personal information is used and shared, and we take your privacy seriously. Please read the
                following to learn more about our Privacy Policy. By visiting or using the Website or Services in any manner, you
                acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that
                we will collect, use, and share your information in the following ways. Any capitalized terms used herein without
                definition shall have the meaning given to them in the 360 Explorer LLP Terms of Use.
            </p>

            <h4>I. WHAT DOES THIS PRIVACY POLICY COVER?</h4>
            <p>
                This Privacy Policy covers our treatment of personally identifiable information ("Personal Information") that we gather
                when you are accessing or using our Website. This policy does not apply to the practices of companies that we do not
                own or control, or to individuals that we do not employ or manage.
            </p>

            <p>
                We do not knowingly collect or solicit personal information from anyone under the age of 13 or knowingly allow such
                persons to register for the Services (as that term is defined in our Terms of Use). If you are under 13, please do not
                attempt to register for the Services or send any information about yourself to us, including your name, address,
                telephone number, or email address. No one under age 13 may provide any personal information to us or on the Services.
                In the event that we learn that we have collected personal information from a child under age 13 without verification
                of parental consent, we will delete that information as quickly as possible. If you believe that we might have any
                information from or about a child under 13, please contact us at info@eurekatrip.com.
            </p>

            <h4>II. WHAT INFORMATION DOES 360 EXPLORER LLP COLLECT?</h4>
            <p>
                We gather various types of Personal Information from our users, as explained more fully below. We may use this
                Personal Information to personalize and improve our services, to allow our users to set up a user account and profile,
                to contact users, to fulfill your requests for certain products and services, to analyze how users utilize the Website,
                and as otherwise set forth in this Privacy Policy. We may share certain types of Personal Information with third
                parties (described in this Section and in Section III below). We collect the following types of information:
            </p>

            <h4>A. Information You Provide to Us:</h4>
            <p>
                We receive and store any information you knowingly provide to us. For example, we collect Personal Information such as
                your name, email address, browser information, travel preferences, ticket purchases, and third-party account credentials
                (for example, your log-in credentials for Facebook or other third-party sites). If you provide your third-party account
                credentials to us, you understand some content and/or information in those accounts ("Third Party Account Information")
                may be transmitted into your account with us if you authorize such transmissions, and that third-party account
                information transmitted to our Services is covered by this Privacy Policy.. You can choose not to provide us with certain 
                information, but then you may not be able to register with us or to take advantage of some of our features. We may anonymize 
                your Personal Information so that you cannot be individually identified, and provide that information to our partners.
            </p>
            <h4>B. Information Collected Automatically:</h4>
            <p>
            Whenever you interact with our Website, we automatically receive and record information on our server logs from your browser 
            including your IP address, "cookie" information, and the page you requested. "Cookies" are identifiers we transfer to your 
            computer or mobile device that allow us to recognize your browser or mobile device and tell us how and when pages in our 
            Website are visited and by how many people. You may be able to change the preferences on your browser or mobile device to
            prevent or limit your computer or device's acceptance of cookies, but this may prevent you from taking advantage of some 
            of our Website's features. If you click on a link to a third-party website, such third party may also transmit cookies to 
            you. This Privacy Policy does not cover the use of cookies by any third parties.</p>
            <p>When we collect usage information (such as the numbers and frequency of visitors to the Website), we only use this data 
            in aggregate form, and not in a manner that would identify you personally. For example, this aggregate data tells us how 
            often users use parts of the Website, so that we can make the Website appealing to as many users as possible. We may also 
            provide this aggregate information to our partners; our partners may use such information to understand how often and in 
            what ways people use our Website, so that they, too, can provide you with an optimal experience. We never disclose aggregate
            information to a partner in a manner that would identify you personally.
            </p>

            <h4>C. E-mail and Other Communications:</h4>
            <p>
            We may contact you, by email or other means; for example, we may send you promotional offers on behalf of other businesses,
            or communicate with you about your use of the Website. Also, we may receive a confirmation when you open an email from us. 
            This confirmation helps us make emails more interesting and improve our service. If you do not want to receive email or other
            mail from us, please indicate your preference by emailing us at info@eurekatrip.com. Please note that if you do not want to
            receive legal notices from us, those legal notices will still govern your use of the Website, and you are responsible for 
            reviewing such legal notices for changes.
            </p>

            <h4>III. WILL 360 EXPLORER LLP SHARE ANY OF THE PERSONAL INFORMATION IT RECEIVES?</h4>
            <p>
            We neither rent nor sell your Personal Information in personally identifiable form to anyone. We share your Personal
            Information in personally identifiable form with third parties as described below.
            </p>

            <h4>A. Affiliated Businesses and Third-Party Websites We Do Not Control:</h4>
            <p>
            In certain situations, businesses or third-party websites we're affiliated with may sell items or provide services 
            to you through the Website (either alone or jointly with us). You can recognize when an affiliated business is 
            associated with such a transaction or service, and we will share your Personal Information with that affiliated 
            business only to the extent that it is related to such transaction or service. For example, we may need to share 
            certain information about you with a Merchant so that the Merchant can allow you to use your Ticket at its place 
            of business. Another such transaction or service may include the ability for you to automatically transmit Third
            Party Account Information to your Website profile or to automatically transmit information in your Website profile
            to your third-party account. We have no control over the policies and practices of third-party websites or businesses 
            as to privacy or anything else, so if you choose to take part in any transaction or service relating to an affiliated 
            website or business, please review all such business' or websites' policies
            </p>

            <h4>B. Agents:</h4>
            <p>
            We employ other companies and people to perform tasks on our behalf and need to share your information with them to 
            provide products or services to you. Unless we tell you differently, our agents do not have any right to use the Personal
            Information we share with them beyond what is necessary to assist us.
            </p>

            <h4>C. User Profiles and Submissions:</h4>
            <p>Certain user profile information, including without limitation a user's name, location, and any video or image content 
            that such user has uploaded to the Website, may be displayed to other users to facilitate user interaction within the Website
            or address your request for 360 Explorer LLP's services. Your account privacy settings may allow you to limit the other users
            who can see the Personal Information in your user profile and/or what information in your user profile is visible to others. 
            Any content you upload to your public user profile, along with any Personal Information or content that you voluntarily disclose
            online in a manner other users can view (on discussion boards, in messages and chat areas, etc.) becomes publicly available, and
            can be collected and used by others. Your username may also be displayed to other users if and when you send messages or comments 
            or upload images or videos through the Website, and other users can contact you through messages and comments. Additionally, if you
            sign into the Website through a third-party social networking site or service, your list of "friends" from that site or service may 
            be automatically imported to the Website, and such "friends," if they are also registered users of the Website, may be able to access 
            certain non-public information you have entered in your Website user profile. Again, we do not control the policies and practices of 
            any other third-party site or service.</p>

            <h4>D. Business Transfers:</h4>
            <p>We may choose to buy or sell assets. In these types of transactions, customer information is typically one of the business assets
             that would be transferred. Also, if we (or substantially all of our assets) are acquired, or if we go out of business, enter bankruptcy,
              or go through some other change of control, Personal Information would be one of the assets transferred to or acquired by a third party.
            </p>
            <h4>E. Protection of 360 Explorer LLP and Others:</h4>
            <p>
            We reserve the right to access, read, preserve, and disclose any information that we reasonably believe is necessary to comply with the 
            law or court order; enforce or apply our conditions of use and other agreements; or protect the rights, property, or safety of 360 Explorer
            LLP, our employees, our users, or others. This includes exchanging information with other companies and organizations for fraud protection
            and credit risk reduction.
            </p>
            <h4>F. Protection of Children:</h4>
            <p>
            360 Explorer LLP's sites are general audience websites and do not offer services directed to children. You must be 16 years of age or older
             to book using 360 Explorer LLP. Should a child whom we know to be under 13 send personal information to us, we will use that information
              only to respond directly to that child to inform him or her that we must have parental consent before receiving his or her personal information
               and will delete the information. If you believe that 360 Explorer LLP has been provided with the personal information of a child under 13 without 
               parental consent, please notify us immediately at info@eurekatrip.com
            </p>
            <h4>G. With Your Consent:</h4>
            <p>
            Except as set forth above, you will be notified when your Personal Information may be shared with third parties
            </p>
        </div>
        <Footer/>
        </>
    );
};

export default Privacy;
