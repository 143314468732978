


import React from 'react';
import { GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google'
import axios from 'axios';
// import { GoogleOAuthProvider } from '@react-oauth/google';


// import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import TwitterLogin from 'react-twitter-auth';
import "../../assets/css/login.css";

const SocialLogin = ({ onSocialLoginSuccess }) => {
    const GOOGLE_CLIENT_ID = '634684914119-vpvroptblisths8trb6la0r8r9sruvdv.apps.googleusercontent.com';   
    const FACEBOOK_APP_ID ='937835227191548';
    const TWITTER_LOGIN_URL = 'https://eurekatrip-user-service.azurewebsites.net/auth/twitter';
    const TWITTER_REQUEST_TOKEN_URL = 'https://eurekatrip-user-service.azurewebsites.net/auth/twitter/request_token';

    const responseGoogle = (response) => {
        console.log(response);
        onSocialLoginSuccess(response.credential, 'google');
    };

    const responseFacebook = (response) => {
        console.log(response);
        onSocialLoginSuccess(response.accessToken, 'facebook');
    };

    const responseLinkedIn = (response) => {
        console.log(response);
        onSocialLoginSuccess(response.code, 'linkedin');
    };

    const responseTwitter = (response) => {
        console.log("response twitter",response)
        response.json().then(body => {
            console.log(body);
            onSocialLoginSuccess(body.oauth_token, 'twitter');
        });
    };
    //  const handleLogin = () => {
    //     axios.get('http://localhost:8080/login/twitter')
    //         .then(response => {
    //             window.location.href = response.data;
    //         })
    //         .catch(error => {
    //             console.error('Error during Twitter login', error);
    //         });
    // };


    return (
        <div>
            <div className="social-button">

            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <GoogleLogin
                onSuccess={responseGoogle}
            />
        </GoogleOAuthProvider>

                {/* <GoogleLogin
                    clientId={GOOGLE_CLIENT_ID}
                    buttonText="Login with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                />*/}
            </div> 
            {/* <div className="social-button">
                <FacebookLogin
                    appId={FACEBOOK_APP_ID}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    icon="fa-facebook"
                />
            </div> */}
            {/* <div className="social-button">
                 <TwitterLogin
                    loginUrl="https://twitter.com/oauth2/authorization/twitter"
                    onFailure={responseTwitter}
                    onSuccess={responseTwitter}
                    //requestTokenUrl="https://eurekatrip-user-service.azurewebsites.net/auth/twitter/request_token"
                  requestTokenUrl="https://twitter.com/oauth2/authorization/twitter"

                />
                 <div>
            </div>
            </div>  */}
        </div>
    );
};

export default SocialLogin;
