import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import '../../../assets/css/recomandation.css'
const Recomandation = () => {

  return (
    <div className="recomandation-container ">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={6} className="d-flex justify-content-center mt-3 mb-3">
            <Card className="custom-card "> 
              <Card.Body className='text-center mt-4 '>
                <Card.Title className="font-schedule-title fs-2 mt-4 mb-3 ms-4">Find sponsorship for your adventure</Card.Title> 
                <Card.Text className="mt-2 mb-5 font-medium  text-center">
                  Great way to organize an adventure trip.
                </Card.Text>
                <Button  className="custom-button"><span className='recommendation-button'>FIND SPONSORSHIP</span></Button> 
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6} className="d-flex justify-content-center mt-3 mb-3">
            <Card className="custom-card"> 
              <Card.Body className='text-center mt-4 '>
                <Card.Title className="font-schedule-title fs-2 mt-4 mb-3 ms-4">Get paid for your recommendations</Card.Title> 
                <Card.Text className="mt-2 mb-5 font-medium  text-center">
                Experts on EurekaTrip get paid when people book from their guides.
                </Card.Text>
                <Button  className="custom-button"><span className='recommendation-button'>BECOME AN EXPERT</span></Button> 
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Recomandation;
