import React, { useState, useEffect, useRef } from "react";
import NavbarHome from "../common/Navbar";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import ScheduleTour from "./ScheduleTour";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Discover from "../common/Discover";
import Footer from "../../footer/footer";
import Navbar from "../common/Navbar";
import Recomandation from "../common/Recomandation";
import Checkout from "./Checkout";

function PaymentSuccessPage() {
  const navigate = useNavigate();
  const [transactionDetails, setTransactionDetails] = useState({});
  const [hasFetchedPayment, setHasFetchedPayment] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted.current && !hasFetchedPayment) {
      console.log(window.location.hash);

      var hash = window.location.hash.split("?");
      var params = {};
      hash[1].split('&').map(hk => {
        let temp = hk.split('=');
        params[temp[0]] = temp[1];
      });
      console.log(params);
      const paymentId = params.paymentId;
      const PayerID = params.PayerID;

      console.log("paymentId", paymentId);
      if (paymentId && PayerID) {
        setHasFetchedPayment(true);
        getPayment(paymentId, PayerID);
      } else {
        const storedData = localStorage.getItem("transactionDetails");
        if (storedData) {
          setTransactionDetails(JSON.parse(storedData));
        }
      }
    }

    return () => {
      isMounted.current = false; // Cleanup on unmount
    };
  }, [hasFetchedPayment]);

  const getPayment = async (paymentId, PayerID) => {
    try {
      const response = await fetch(
        `https://eurekatrip-payment-service.azurewebsites.net/paypal/success?paymentId=${paymentId}&PayerID=${PayerID}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTransactionDetails(data);

      toast.success("Tour Booked successfully");
      const newUrl = window.location.origin + "/paypal/success";
      window.history.replaceState(null, null, newUrl);
    } catch (error) {
      console.error("Error fetching tour data:", error);
    }
  };

  // const handleViewAll = () => {
  //   navigate("/booking-details");
  // };

  return (
    <>
      <div
        className="d-flex justify-content-center"
        style={{
          background: "#f0f0f0",
        }}
      >

      </div>


      {transactionDetails && transactionDetails.data && transactionDetails.data.tourEntity && transactionDetails.data.tourEntity.tourId ? (
        <Checkout transactionData={transactionDetails} selectedSchedule={transactionDetails.data.scheduleId} Participants='9' />
      ) : (
        <div className="ms-2" style={{ marginTop: "130px" }}>
          Loading payment information...
        </div>
      )}


      <ToastContainer />

    </>
  );
}

export default PaymentSuccessPage;
