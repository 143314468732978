import React from "react";
import "../../../assets/css/expeditionInfo.css";
import expiditionImg from "../../../assets/image/expiditionImg.png";
import { Col, Row } from "react-bootstrap";

const ExpeditionInfo = () => {
  return (
    <div className="custom-container container-fluid px-5">
      <Row>
        <Col className="d-flex justify-content-center">
          <div className="info-text text-center">
            <h2>Facts</h2>
            <div className="expedition-main px-5">
              <span className="font-expedition-title text-wrap">
                As a specialist climbing and expedition company <br />
                <span className="expedition-title">
                  we offer world-class adventures and training designed to
                  transform lives. Here are some key highlights of our journey
                  so far
                </span>
              </span>
            </div>
            <p className="text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
            Join us and become part of a growing community of adventurers who push boundaries and achieve the extraordinary.
            </p>
          </div>
        </Col>
      </Row>

      <Row className="facts-row mb-5  ">
        <Col md={3}>
          <div className="fact">
            <div className="fact-icon">
              <img className="expiditionImg" src={expiditionImg} alt="star" />
            </div>
            <div className="fact-text">
              <h3 className="font-expedition-title">75K+</h3>
              <span className="card-expedition-title">
                Empowered by Adventure
              </span>
            </div>
          </div>
        </Col>

        <Col md={3}>
          <div className="fact">
            <div className="fact-icon">
              <img className="expiditionImg" src={expiditionImg} alt="star" />
            </div>
            <div className="fact-text">
              <h3 className="font-expedition-title">350+</h3>
              <span className="card-expedition-title">
                Successful Climbs on Kilimanjaro
              </span>
            </div>
          </div>
        </Col>

        <Col md={3}>
          <div className="fact">
            <div className="fact-icon">
              <img className="expiditionImg" src={expiditionImg} alt="star" />
            </div>
            <div className="fact-text">
              <h3 className="font-expedition-title">1000+</h3>
              <span className="card-expedition-title">
                Global Treks and Expeditions
              </span>
            </div>
          </div>
        </Col>

        <Col md={3}>
          <div className="fact">
            <div className="fact-icon">
              <img className="expiditionImg" src={expiditionImg} alt="star" />
            </div>
            <div className="fact-text">
              <h3 className="font-expedition-title">98%</h3>
              <span className="card-expedition-title">
                Summit Success Rate on Kilimanjaro
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ExpeditionInfo;
